import { ReactElement, useCallback, memo } from "react";
import { useFetch } from "../../hooks";
import Form from "../custom/Form";

function DonateOther(): ReactElement {
  const [post, loading] = useFetch("/contact/donate");
  const submit = useCallback(
    async (donateInfo) =>
      await post({ data: donateInfo, message: "Message Sent!" }),
    [post]
  );
  return (
    <>
      <Form
        loading={loading}
        onSubmit={submit}
        initialData={{
          FullName: "anonymous",
          Email: "",
          Phone: "",
          Message: "",
        }}
        submitLabel="Donate"
        Fields={[
          { name: "FullName", optional: true },
          { name: "Email", sm: 6 },
          { name: "Phone", sm: 6, optional: true },
          { name: "Message", multiline: true },
        ]}
      />
    </>
  );
}

export default memo(DonateOther);