import { ReactElement, useMemo, memo } from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { PreviousWalk } from "../components";
import { PreviousWalkConstant } from "../constants/PreviousWalk.Constant";
import Grid from '@mui/material/Grid';
import PublicNavbar from "../components/navbar/Public.Navbar";
import Image1 from "./2023_walk/1.jpg";
import Image2 from "./2023_walk/2.jpg";
import Image3 from "./2023_walk/3.jpg";
import Image4 from "./2023_walk/4.jpg";
import Image5 from "./2023_walk/5.jpg";
import Image6 from "./2023_walk/6.jpg";
import Image7 from "./2023_walk/7.jpg";
import Image8 from "./2023_walk/8.jpg";
import Image9 from "./2023_walk/9.jpg";
import Image10 from "./2023_walk/10.jpg";
import Image11 from "./2023_walk/11.jpg";
import Image12 from "./2023_walk/12.jpg";
import Image13 from "./2023_walk/13.jpg";
import Image14 from "./2023_walk/14.jpg";
import Image15 from "./2023_walk/15.jpg";
import Image16 from "./2023_walk/16.jpg";
import Image17 from "./2023_walk/17.jpg";
import Image18 from "./2023_walk/18.jpg";
import Image19 from "./2023_walk/19.jpg";
import Image20 from "./2023_walk/20.jpg";
import Image21 from "./2023_walk/21.jpg";
import Image22 from "./2023_walk/22.jpg";
import Image23 from "./2023_walk/23.jpg";
import Image24 from "./2023_walk/24.jpg";
import Image25 from "./2023_walk/25.jpg";
import Image26 from "./2023_walk/26.jpg";
import Image27 from "./2023_walk/27.jpg";
import Image28 from "./2023_walk/28.jpg";
import Image29 from "./2023_walk/29.jpg";
import Image30 from "./2023_walk/30.jpg";
import Image31 from "./2023_walk/31.jpg";
import Image32 from "./2023_walk/32.jpg";
import Image33 from "./2023_walk/33.jpg";
import Image34 from "./2023_walk/34.jpg";
import Image35 from "./2023_walk/35.jpg";
import Image36 from "./2023_walk/36.jpg";
import Image37 from "./2023_walk/37.jpg";
import Image38 from "./2023_walk/38.jpg";
import Image39 from "./2023_walk/39.jpg";


function PreviousWalkPage(): ReactElement {
    const memoizedSponsors = useMemo(() => PreviousWalkConstant, []);
    return (
        <PublicNavbar sx={{ pb: 6 }}>
            <Stack spacing={3} mt={5} mx={2} alignItems="center">
                <Typography
                    variant="h2"
                    component="h2"
                    fontWeight={500}
                    textAlign="center"
                    mb={4}
                >
                    Cape 2 Care 2023
                </Typography>

                <Typography variant="body2" style={{ textAlign: "center" }}>
                We've walked for our children who have been failed, failed by their circumstances, failed by their adult figures, failed by their environment, failed by false promises, unaware of how empowered they actually are, and the ability they have to change their circumstances. <br/><br/>

We've walked for our Children with potential who just need the ONE opportunity to rise above their circumstances and change the failure into freedom.<br/><br/>

We've walked from Vereeniging to Hartenbos, 1480km, Raising R300 000 for Dynamic Women for Children and taking hands with the National Child Protection Hotline launching contact numbers for them as we walk through the different towns. <br/> <br/>We are beyond grateful for each and everyone who made this journey possible!<br/><br/>

We at Dynamic Women for Children used the money that we've raised with Cape2Care 2023 to build the hostel parents home, and pay university fees for our 3 students currently enrolled at New Bridge Academy.<br/><br/>

Excited to continue with the Cape2Care journey...<br /><br />
                </Typography>
                <Grid container spacing={8} justifyContent="center" width="100%">
                    <Box
                        component="img"
                        src={Image1}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="1rem auto"
                        my={3}
                    /> <Box
                        component="img"
                        src={Image2}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="1rem auto"
                        my={3}
                    /> <Box
                        component="img"
                        src={Image3}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="1rem auto"
                        my={3}
                    /> <Box
                        component="img"
                        src={Image4}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    /> <Box
                        component="img"
                        src={Image5}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="1rem auto"
                        my={3}
                    />
                    <Box
                        component="img"
                        src={Image6}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image7}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image8}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image9}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image10}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />

                    <Box
                        component="img"
                        src={Image11}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image12}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image13}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image14}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image15}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="1rem auto"
                        my={3}
                    />

                    <Box
                        component="img"
                        src={Image16}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />


                    <Box
                        component="img"
                        src={Image17}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />

                    <Box
                        component="img"
                        src={Image18}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image19}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image20}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    {/* <Box
  component="img"
  src={Image26}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/>  */}
                    <Box
                        component="img"
                        src={Image21}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image22}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image23}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image24}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image25}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image26}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />

                    <Box
                        component="img"
                        src={Image27}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image28}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image29}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image30}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image31}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image32}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image33}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    />
                    <Box
                        component="img"
                        src={Image34}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    /> 
                    <Box
                        component="img"
                        src={Image35}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    /> 
                    <Box
                        component="img"
                        src={Image36}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    /> 
                    <Box
                        component="img"
                        src={Image37}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    /> 
                    <Box
                        component="img"
                        src={Image38}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    /> 
                    <Box
                        component="img"
                        src={Image39}
                        width="100%"
                        maxWidth="20rem"
                        alignSelf="center"
                        m="0rem auto"
                        my={1}
                    /> 
                </Grid>
            </Stack>
            <Stack spacing={3} mt={5} mx={2} alignItems="center">
                <Stack direction={{ xs: "column", md: "row" }} spacing={6}>
                    <Stack spacing={2}>
                        <Typography variant="body2" style={{ textAlign: "left", color: "#e43f83" }}>Cape 2 Care 2023 Sponsors:<br /><br /></Typography>
                        <Typography variant="body2" >

                            - Vermelion Designs & Developers <br />
                            - Groot Ontbyt <br />
                            - The Local Choice Pharmacy Med Value <br />
                            - Willa Krause Skin Care <br />
                            - AQuelle Water <br />
                            - Goldwagen Springsgate <br />
                            - Goldwagen Lephalale <br />
                            - etHaKallah <br />
                            - Eva Natuurlik <br />



                        </Typography>
                    </Stack>

                    <Stack spacing={2}>

                        <Typography variant="body2" style={{ textAlign: "left", color: "#e43f83" }}>





                            Cape 2 Care 2023 Accommodation: <br /><br />
                        </Typography>
                        <Typography variant="body2" style={{ textAlign: "left" }}>

                            - Dancing River Guesthouse <br />
                            - Villa Bonheur Guesthouse <br />
                            - Lindley Hotel <br />
                            - Eingedi Retreat <br />
                            - Ons Hotel <br />
                            - The Jackal Guesthouse <br />
                            - The Symphony Guesthouse <br />
                            - Katberg Mountain Resort and Hotel <br />
                            - Acorn Cottage <br />
                            - Framesby Guesthouse <br />
                            - @28Kabeljouws <br />
                            - Outeniqua Travel Lodge <br /><br />

                        </Typography>
                    </Stack>
                </Stack>

                <Grid
                    container
                    spacing={4}

                    mx='auto'
                    justifyContent='center'
                    position='relative'
                    width='100%'
                >
                    <Grid item xs={12} textAlign='center'>
                        <Typography variant='h5' fontWeight={600}>
                            Contributors 2023
                        </Typography>
                    </Grid>

                    {/* {contributors.map(contributor => ( */}
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Hanna
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        A. Nel

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Nick Coetzee

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Marie

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Laerskool Kameelfontein


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Schalkwyk Attorneys


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        JT


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Helena


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Fanus van der Walt


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Richards Attorneys


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Marlene Duvenhage


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Kristen


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Rikalize Vosloo

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Minette


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Gereformeerde Kerk Wapadrant

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Elizabeth Gevers


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        WEC


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Army Boots


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Marina du Plooy


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Elaine


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        PJ Gouws 


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Maretha


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Y Bruwer


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Ad-hoc Plumbers


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Laetitia Coetzee


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Antionette


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Izelle Venter 

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Erika van Wyk

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Freda


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Jan Meyburgh


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        M Stander


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Marina


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        PG - Relentless Consulting

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Carey-lee Krige


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Marina

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        MC Engelbrecht


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Doxa Deo Boekklub


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        VLU - Naledi


                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Anita Wilson

                        </Typography>
                    </Grid>

                    {/* ))} */}

                </Grid>
                <Stack mt={5} direction={{ xs: "column", md: "row" }} spacing={6}>
                    <Stack spacing={2}>
                        <Typography variant="body2" style={{ textAlign: "center", }}>
                            {/* Every individual, family or company that made a donation towards Cape2Care 2023! <br /><br /> */}

                            Cape 2 Care 2023 <br />
                            We've walked for our children... <br /><br />

                            "Let's raise children who won't have to recover from their childhoods."
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </PublicNavbar>
    );
}
export default memo(PreviousWalkPage);