import { ReactElement, memo } from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { WhyWeWalk } from "../components";
import { WhyWeWalkConstant } from "../constants";
import PublicNavbar from "../components/navbar/Public.Navbar";

function WhyWeWalkPage(): ReactElement {
  return (
    <PublicNavbar sx={{ pb: 6 }}>
      <Stack mt={5} spacing={8} divider={<Divider flexItem />} width="100%">
        {Object.keys(WhyWeWalkConstant).map((key, index) => (
          <WhyWeWalk
            key={index}
            name={key.replaceAll("_", " ")}
            invert={index % 2 === 0}
            {...WhyWeWalkConstant[key]}
          />
        ))}
      </Stack>
    </PublicNavbar>
  );
}
export default memo(WhyWeWalkPage);