import { ReactElement, useCallback, memo, FormEvent } from 'react';
import Grid, { GridProps as MuiGridProps } from '@mui/material/Grid';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { WithButtonProps, GridDivider, Action, SxType } from '../..';
import Field, { FieldOptions } from './Field';
import useForm from '../../../hooks/useForm';

type FormFieldProps<GenericData> = FieldOptions & {
    name: keyof GenericData;
};

type FormFieldOptions<GenericData> = FormFieldProps<GenericData> | 'divider';

export interface FormProps<GenericData> extends FormContainerProps {
    onSubmit: (data: GenericData) => void;
    initialData: GenericData;
    size?: 'small' | 'medium';
    disableAction?: boolean;
    loading?: boolean;
    submitLabel?: string;
    Fields?: FormFieldOptions<GenericData>[];
    ActionProps?: WithButtonProps;
    FieldProps?: FieldOptions;
}

function Form<FormGenericData>({
    onSubmit,
    initialData,
    size,
    disableAction,
    loading,
    submitLabel,
    Fields,
    FieldProps,
    ActionProps,
    children,
    ...props
}: FormProps<FormGenericData>): ReactElement {
    const [data, onChange] = useForm<FormGenericData>(initialData);
    const submit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onSubmit(data);
        },
        [onSubmit, data]
    );
    return (
        <form onSubmit={submit}>
            <FormContainer {...props}>
                {Fields?.map((field, index) =>
                    field === 'divider' ? (
                        <GridDivider key={index} />
                    ) : (
                        <Field
                            key={field.name}
                            grid={!props.disableGrid}
                            {...field}
                            onChange={onChange(field.name)}
                            size={size}
                            name={field.name}
                            variant={field.variant}
                        />
                    )
                )}
                {children}
                {!disableAction && (
                    <Action
                        av='Button'
                        type='submit'
                        size={size}
                        loading={loading}
                        grid={!props.disableGrid}
                        {...ActionProps}
                        label={submitLabel ?? 'submit'}
                    />
                )}
            </FormContainer>
        </form>
    );
}

interface FormContainerProps extends WithChildren {
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    title?: string;
    disableGrid?: boolean;
    sx?: SxType;
    TitleProps?: TypographyProps;
    GridProps?: MuiGridProps;
}

function FormContainer({
    maxWidth,
    title,
    disableGrid,
    sx,
    TitleProps,
    GridProps,
    children
}: FormContainerProps): ReactElement {
    return (
        <Container
            maxWidth={maxWidth ? maxWidth : 'md'}
            sx={{ m: 0, ...sx }}
            disableGutters
        >
            {title && (
                <Typography
                    variant='h2'
                    fontWeight={600}
                    mb={3}
                    textAlign='center'
                    {...TitleProps}
                >
                    {title}
                </Typography>
            )}
            {disableGrid ? (
                children
            ) : (
                <Grid
                    container
                    {...GridProps}
                    spacing={GridProps?.spacing ? GridProps.spacing : 3}
                >
                    {children}
                </Grid>
            )}
        </Container>
    );
}

export default memo(Form) as typeof Form;
