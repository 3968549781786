import Palette from "./Palette";
import routes, {
  routeTypes,
  adminRoutes,
  publicRoutes,
  INavigate,
  WithLink,
} from "./routes.config";
//! LIVE
import { routeConfig, payfastConfig, console_log } from "./live.config";
//? DEV
// import { routeConfig, payfastConfig, console_log } from "./dev.config";
const radius = {
  default: "1rem",
  small: "5px",
  medium: "10px",
  large: "2rem",
};

const dateFormats = {
  short: "D MMM HH:mm",
  full: "DD/MM/YY HH:mm",
  call: "YYYY-MM-DD HH:mm:ss",
  normal: "DD/MM/YYYY",
};

const config = {
  routeConfig,
  payfastConfig,
  console_log,
  Palette,
  radius,
  dateFormats,
};

export {
  config as default,
  routeConfig,
  payfastConfig,
  console_log,
  Palette,
  radius,
  adminRoutes,
  publicRoutes,
  dateFormats,
  routes,
};
export type { routeTypes, INavigate, WithLink };
