import { ReactElement, memo } from 'react';
import { Blogs, PublicNavbar } from '../components';

function BlogPage(): ReactElement {
    return (
        <PublicNavbar>
            <Blogs />
        </PublicNavbar>
    );
}

export default memo(BlogPage);
