import { ReactElement, useCallback, memo } from "react";
import Form from "../custom/Form";
import { useFetch, useForm } from "../../hooks";

function AddSponsor(): ReactElement {
  const [post, loading] = useFetch("/sponsors/add");
  const [details, onChange, reset] = useForm({
    Logo: "",
    Link: "",
  });

  const addSponsor = useCallback(
    async () => await post({ data: details, message: "Added Sponsor" }),
    [details, post]
  );

  return (
    <></>
    // <Form
    //     onFormSubmit={addSponsor}
    //     reset={reset}
    //     label='Add Sponsor'
    //     Fields={[
    //         { name: 'Logo', onChange: onChange('Logo') },
    //         { name: 'Link', onChange: onChange('Link') }
    //     ]}
    //     loading={loading}
    // />
  );
}
export default memo(AddSponsor);