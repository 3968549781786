import { memo, ReactElement, useEffect } from "react";
import {
  HomeHeader,
  Needs,
  PublicNavbar,
  Sponsors,
  SponsorsLogos,
  Wishlist,
} from "../components";
import FeaturedBlog from "../components/blog/FeaturedBlog";
import { useSponsors } from "../context";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import Video from "../assets/videos/Cape2Care.mp4";
import Grid from "@mui/material/Grid";

function HomePage(): ReactElement {
  // const { getContributors } = useSponsors();

  // useEffect(() => {
  //   getContributors();
  // }, [getContributors]);

  return (
    <PublicNavbar>
      <HomeHeader />
      {/*<Stack*/}
      {/*  spacing={3}*/}
      {/*  justifyContent="center"*/}
      {/*  mt={5}*/}
      {/*  alignItems={{ xs: "center", lg: "stretch" }}*/}
      {/*  direction={{ xs: "column", lg: "row" }}*/}
      {/*>*/}





      {/* <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="stretch"
        mt={5}
      >
        <FeaturedBlog
          title="Project launch interview"
          description="Today we had our interview with Die Groot Ontbyt, talking about why we
        are planning this walk to CapeTown and everything you need to know about
        it. You can watch the whole interview and maybe also decide you want to
        be apart of this journey by becoming a sponsor or even just donating. We
        thank everyone who has already contributed and sponsored to this amazing
        initiative."
          youtubeUrl="https://www.youtube-nocookie.com/embed/RittB1GTyQ0"
        />
        <FeaturedBlog
          title="Project launch article"
          link="https://afriforum.co.za/agt-dinamiese-vroue-pak-staptog-vir-kinders-uit-informele-nedersettings-aan/"
          description="Our countdown has started and we are almost ready for our long walk for our children. In this article we explain why we are doing this and what we are planning to do in the future. We love our kids and want to improve their lives by giving them a second chance."
          imageUrl="https://afriforum.co.za/wp-content/uploads/2022/01/IMG_8239-scaled-e1642768451164-2048x1290.jpg"
        />
        <FeaturedBlog
          title="Our kids are amazing"
          description="Our kids made this heart warming video wishing us luck on our long walk to Cape Town and we could not be more happy. Without them we would not be doing this. We are walking for them and to make it possible for them to have a second chance in life. Follow our journey and support the cause, we are doing it for them."
          video={Video}
        />
        <FeaturedBlog
          title="On the road"
          description="Today Die Groot Ontbyt called us on our way to Vredeford to check in on our progress. We are very excited for the long to Cape Town and to raise awareness and money for our children and their futures."
          youtubeUrl="https://www.youtube.com/embed/HwdBE56xvMA"
        />
      </Grid> */}
      {/*</Stack>*/}
      <Stack spacing={3} mt={5} mx={2} alignItems="center">
        <Typography
          variant="h2"
          component="h2"
          fontWeight={500}
          textAlign="center"
          mb={4}
        >
          Our Route
        </Typography>
        <Stack direction={{ xs: "column", md: "row" }} spacing={6}>
          <Stack spacing={2}>
            {[
              {
                date: "27 January -",
                destinations: " \xa0 Vereeniging to Potchefstroom ( 90km)",
              },
              {
                date: "28 January -",
                destinations: " \xa0 Potchefstroom to Klerksdorp (48km)",
              },
              { date: "29 January -", destinations: " \xa0 Klerksdorp to Wolmaransstad (97km)" },
              { date: "30 January -", destinations: " \xa0 Wolmaransstad to Christiana (122km)" },
              {
                date: "31 January -",
                destinations: " \xa0 Christiana to  Kimberly (113km)",
              },
              {
                date: "1 February -",
                destinations: " \xa0 Kimberly to Koffiefontein (85km)",
              },
              { date: "2 February -", destinations: " \xa0 Koffiefontein to Van der Kloof (84km)" },
              { date: "3 February -", destinations: " \xa0 Van der Kloof to Phillipstown (60km)" },
              // { date: "Sunday 4 February", destinations: " \xa0 Rest Day" },
              { date: "5 February -", destinations: " \xa0 Phillipstown to De Aar (59km)" },
              { date: "6 February -", destinations: " \xa0 De Aar to Richmond (91km)" },


            ].map((item) => (
              <Stack key={item.date} direction="row">
                <Typography variant="body2"
                // sx={{ width: "9.5rem" }}
                >
                  {item.date}
                </Typography>
                <Typography style={{ color: "#e43f83" }} variant="body2"
                // sx={{ width: "12rem" }}
                >
                  {item.destinations}
                </Typography>
              </Stack>
            ))}
          </Stack>
          <Stack spacing={2}>
            {[

              { date: "7 February -", destinations: " \xa0 Richmond to 3 Susters (106km)" },
              {
                date: "8 February -",
                destinations: " \xa0 3 Susters to Beaufort Wes (73km)",
              },
              {
                date: "9 February -",
                destinations: " \xa0 Beaufort-Wes to Klaarstroom (123km)",
              },
              { date: "10 February -", destinations: " \xa0 Klaarstroom to Oudtshoorn (60km)" },
              // { date: "Sunday 11 February", destinations: " \xa0 Rest Day" },
              { date: "12 February -", destinations: " \xa0 Oudtshoorn to Ladismith (99km)" },
              { date: "13 February -", destinations: " \xa0 Ladismith to Barrydale (77km)" },
              { date: "14 February -", destinations: " \xa0 Barrydale to Swellendam (45km)" },
              { date: "15 February -", destinations: " \xa0 Swellendam to Bredasdorp (74km)" },
              { date: "16 February -", destinations: " \xa0 Bredasdorp to Cape Agulhas (39km)" },

            ].map((item) => (
              <Stack key={item.date} direction="row">
                <Typography variant="body2"
                // sx={{ width: "11rem" }}
                >
                  {item.date}
                </Typography>
                <Typography variant="body2" style={{ color: "#e43f83" }}
                // sx={{ width: "18rem" }}
                >
                  {item.destinations}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
      <SponsorsLogos />
      <Needs />
      <Sponsors />
      <Wishlist />
    </PublicNavbar>
  );
}

export default memo(HomePage);
