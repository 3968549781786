import { ReactElement, memo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSponsors } from '../../context';
import { Loading } from '..';

function SponsorsContribute(): ReactElement {
    const { contributors, contributorsLoading } = useSponsors();
    return (
        <Grid
            container
            spacing={4}
            mt={2}
            mx='auto'
            justifyContent='center'
            position='relative'
            width='100%'
        >
            <Grid item xs={12} textAlign='center'>
                <Typography variant='h5' fontWeight={600}
                >
                    Contributors
                </Typography>
            </Grid>
            <Loading loading={contributorsLoading} relative>
                {/* {contributors.map(contributor => ( */}
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        A. Nel
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        B. Schoeman


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        <strong>
                            Barteld Kooi
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Belinda

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Cape2Care Borg


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Cara

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Clarisa van der Schyff

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Donasie

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        E. Otto


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Elaine
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Elna Boman
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        FRM Donation
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Hans Coetzee
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Helena
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Hendri
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        <strong>
                            Heuningland Toere
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Izelle Venter Potgieter
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Liezel
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Liezl Ferreira
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Melanie du Plessis

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        <strong>
                            Minette Weichelt
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        NG Phillipstown
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        NHKA Wolmaranstad
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        PJ Gouws
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Sylvia
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Theo van der Merwe


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Vanda Hiscock

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Wollies
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        <strong>
                            Slabbert & Slabbert
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Anel
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Mariki
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        PMT FRM
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        OB MPT Blessing
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        PJL Vermeulen
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        <strong>
                            Pth Water Pierre Massyn
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        <strong>
                            Maranda van Zyl
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        <strong>
                            Pretoria Country Club
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Lorraine
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                    Alida Stoltz
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                    M Kapp
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        <strong>
                        Rockblend Readymix 
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        <strong>
                        PB Louw Middel
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        <strong>
                        Dr. C Blignaut
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        <strong>
                        Carel van der Wath
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                    Annie Brent
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                    Helene Bekker
                    </Typography>
                </Grid>
                {/* <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Marina du Plooy


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Elaine


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        PJ Gouws


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Maretha


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Y Bruwer


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Ad-hoc Plumbers


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Laetitia Coetzee


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Antionette


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Izelle Venter

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Erika van Wyk

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Freda


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Jan Meyburgh


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        M Stander


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Marina


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        PG - Relentless Consulting

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Carey-lee Krige


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Marina

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        MC Engelbrecht


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Doxa Deo Boekklub


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        VLU - Naledi


                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                    <Typography variant='subtitle2'>
                        Anita Wilson
                    </Typography>
                </Grid> */}

                {/* ))} */}
            </Loading>
        </Grid>
    );
}

export default memo(SponsorsContribute);