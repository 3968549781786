import { useState, useEffect, useCallback, MouseEventHandler } from "react";
import {
  useNavigate as useRouteNavigator,
  useLocation,
  NavigateOptions,
} from "react-router";
import { routeTypes } from "../config";
interface MyNavigateOptions extends NavigateOptions {
  ext?: string;
}

export type navigateRouteTypes = routeTypes | 1 | -1 | -2;

export default function useNavigate() {
  const routeNavigator = useRouteNavigator();
  const location = useLocation();

  const [path, setPath] = useState<unknown>(location.pathname);
  const [from, setFrom] = useState<unknown>(
    location.state?.from?.pathname || "/"
  );

  useEffect(
    () => setFrom(location.state?.from?.pathname || "/"),
    [location.state?.from?.pathname]
  );

  useEffect(() => setPath(location.pathname), [location.pathname]);

  const navigate = useCallback(
    (path: navigateRouteTypes, options?: MyNavigateOptions) =>
      options?.ext
        ? routeNavigator(path + options.ext, options)
        : typeof path === "number"
        ? routeNavigator(path)
        : routeNavigator(path, options),
    [routeNavigator]
  );

  const navigateOnClick = useCallback(
    (
        {
          path,
          options,
        }: { path: navigateRouteTypes; options?: MyNavigateOptions },
        onClick?:
          | MouseEventHandler<HTMLButtonElement | HTMLDivElement>
          | undefined
      ) =>
      (e: any) => {
        navigate(path, options);
        onClick && onClick(e);
      },
    [navigate]
  );
  return {
    navigate,
    location,
    routeNavigator,
    navigateOnClick,
    hash: location.hash,
    search: location.search,
    path: path as routeTypes,
    from: from as routeTypes,
  };
}