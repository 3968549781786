import { ReactElement } from 'react';
import { Typography } from '@mui/material';

export default function NotFoundPage(): ReactElement {
    return (
        <div>
            <Typography variant='h1'>Page Not Found</Typography>
        </div>
    );
}
