import { ReactElement, memo } from 'react';
import { Book, Home, People } from '@mui/icons-material';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import { Action } from '..';
import { useAuth } from '../../context';
import Navbar2, { NavbarProps } from './Navbar';

function AdminNavbar(
    props: WithChildren<Omit<NavbarProps, 'links' | 'secondaryLinks'>>
): ReactElement {
    const { logout } = useAuth();
    return (
        <Navbar2
            {...props}
            color='primary'
            links={[
                { to: '/admin', label: 'Dashboard', icon: <Home /> },
                {
                    to: '/admin/contributors',
                    label: 'Contributors',
                    icon: <People />
                },
                { to: '/admin/blogs', label: 'Blogs', icon: <Book /> }
            ]}
            secondaryLinks={[
                { to: '/admin', label: 'Settings', icon: <Settings /> },
                { label: 'Logout', icon: <Logout />, onClick: logout }
            ]}
            endActions={
                <>
                    <Action
                        av='IconButton'
                        Icon={<Settings />}
                        color='inherit'
                    />
                    <Action
                        av='IconButton'
                        Icon={<Logout />}
                        color='inherit'
                        onClick={logout}
                    />
                </>
            }
            mobileEndActions={
                <>
                    <Action
                        av='IconButton'
                        Icon={<Settings />}
                        color='inherit'
                    />
                    <Action
                        av='IconButton'
                        Icon={<Logout />}
                        color='inherit'
                        onClick={logout}
                    />
                </>
            }
        />
    );
}

export default memo(AdminNavbar);
