import { ReactElement, useState, useCallback, useEffect, memo } from "react";
import moment from "moment";
import Typography from "@mui/material/Typography";
import { dateFormats } from "../config";
import { Loading, AdminNavbar, Table } from "../components";
import { useAuth, useSponsors } from "../context";
import { useFetch, useInput } from "../hooks";
import Drawer from "@mui/material/Drawer";
import Paper from "@mui/material/Paper";

function AdminContributorsPage(): ReactElement {
  const { MemberGuid, SessionGuid } = useAuth();
  const { getContributors, contributors, contributorsLoading, addContributor } =
    useSponsors();
  const [post] = useFetch("/sponsors/contributor/add");
  const [search, updateSearch] = useInput(null);
  const [total, setTotal] = useState(0);
  const addContributorHandler = useCallback(
    async (contributor) => {
      await post({
        data: {
          ...contributor,
          MemberGuid: MemberGuid,
          SessionGuid: SessionGuid,
          message: "Added contributor",
        },
      });
      addContributor({
        ...contributor,
        DateAdded: moment(new Date()).format(dateFormats.normal),
      });
    },
    [addContributor, MemberGuid, SessionGuid, post]
  );
  useEffect(() => {
    getContributors();
  }, [getContributors]);
  useEffect(() => {
    setTotal(0);
    contributors.forEach((contributor) =>
      setTotal((prevTotal) => prevTotal + contributor.Amount)
    );
  }, [setTotal, contributors]);
  return (
    <AdminNavbar updateSearch={updateSearch}>
      <Loading loading={contributorsLoading}>
        <Table
          data={contributors}
          search={search}
          loading={contributorsLoading}
          title="Contributors"
          columns={["FullName", "Link", "Amount", "DateAdded"]}
          sortColumns={["FullName", "DateAdded"]}
          editableColumns={["FullName", "Link", "Amount"]}
          initialRow={{
            FullName: "",
            Link: null,
            Amount: 0,
          }}
          sortByDefault="DateAdded"
          actionsPosition="end"
          // editHandler={data => console.log('Edit: ', data)}
          addHandler={addContributorHandler}
        />

        <Typography
          variant="subtitle1"
          component="legend"
          fontWeight={500}
          m={2}
          mt={1}
        >
          Total: R {total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}
        </Typography>
      </Loading>
    </AdminNavbar>
  );
}

function addDrawer({
  open,
  toggle,
}: {
  open: boolean;
  toggle: (condition?: boolean) => void;
}) {
  return (
    <Drawer anchor="left" open={open} onClose={() => toggle(false)}>
      <Paper sx={{ width: "80vw" }}>
        <h1>Add Drawer</h1>
      </Paper>
    </Drawer>
  );
}

export default memo(AdminContributorsPage);
