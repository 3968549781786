import { PaletteMode } from '@mui/material';
import { SimplePaletteColorOptions } from '@mui/material/styles';
import { TypeBackground, TypeText } from '@mui/material/styles/createPalette';

export interface PaletteProps {
    primary?: SimplePaletteColorOptions;
    secondary?: SimplePaletteColorOptions;
    error?: SimplePaletteColorOptions;
    warning?: SimplePaletteColorOptions;
    info?: SimplePaletteColorOptions;
    success?: SimplePaletteColorOptions;
    text?: Partial<TypeText>;
    mode?: PaletteMode;
    background: Partial<TypeBackground>;
}
export const Palette: PaletteProps = {
    primary: { main: '#2eb6c0', contrastText: '#111' },
    secondary: { main: '#e43f83', contrastText: '#000' },
    background: { default: '#e9e9e9', paper: '#ddd' },
    mode: 'light'
};

export default Palette;
