import { GridSize } from "@mui/material/Grid";
export const PreviousWalkConstant: Array<{
  logo: string;
  size: GridOptions;
  href?: string;
}> = [
  {
    size: 6,
    logo: "/images/logos/vermelion.png",
    href: "https://vermelion.net",
  },
  // {
  //   size: 6,
  //   logo: "/images/logos/die_groot_ontbyt.png",
  //   href: "https://www.grootfm.co.za/die-groot-ontbyt/",
  // },
  // {
  //   size: 4,
  //   logo: "/images/logos/gys_pitzer.png",
  //   href: "https://www.gyspitzermotors.co.za/",
  // },
  // {
  //   size: 4,
  //   logo: "/images/logos/oasis.png",
  //   href: "https://oasiswater.co.za/",
  // },
  // {
  //   size: 4,
  //   logo: "/images/logos/haval.png",
  //   href: "https://www.havalhatfield.co.za/",
  // },
  // {
  //   size: 4,
  //   logo: "/images/logos/gvk.png",
  //   href: "https://www.siyazama.co.za/",
  // },
];
