import { ReactElement, useMemo, memo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { SponsorsConstant } from "../../constants";
import { Action, SponsorButton } from "..";

function SponsorsLogos(): ReactElement {
  const memoizedSponsors = useMemo(() => SponsorsConstant, []);
  return (
    <Stack width="100%" alignItems="center" justifyContent="center" my={7}>
      <Typography
        variant="h2"
        component="h2"
        fontWeight={500}
        textAlign="center"
      // mb={4}
      >
        Valued Sponsors
      </Typography>
      <Grid container spacing={8} justifyContent="center" width="100%">
        {memoizedSponsors.map((sponsor, index) => (
          <Action
            key={sponsor.href ?? index}
            av="Image"
            label={`${sponsor} logo`}
            src={sponsor.logo}
            maxWidth="100%"
            target="_blank"
            href={sponsor.href}
            sm={sponsor.size === 3 ? 4 : sponsor.size}
            md={sponsor.size}
            xl={sponsor.size === 3 ? 2 : sponsor.size}
            grid
          />
        ))}
      </Grid>
      <SponsorButton label="Become a sponsor" />
    </Stack>
  );
}

export default memo(SponsorsLogos);
