import { useReducer } from 'react';
import { ConfigContext } from './Config.Context';
import {
    configReducer,
    configReducerInit,
    configReducerInitialState
} from './config.reducer';

export default function ConfigProvider({ children }: WithChildren) {
    const [config, dispatchConfig] = useReducer(
        configReducer,
        configReducerInitialState,
        configReducerInit
    );

    return (
        <ConfigContext.Provider
            value={{
                ...config,
                config,
                dispatchConfig
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
}
