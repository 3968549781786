import React, { memo, ReactElement, useState } from "react";
import Stack from "@mui/material/Stack";
import { Action, AdminNavbar } from "../components";
import { useFetch } from "../hooks";
import { styled } from "@mui/material/styles";
import { PhotoCamera } from "@mui/icons-material";
import axios from "axios";

const Input = styled("input")({
  display: "none",
});

function AdminBlogsPage(): ReactElement {
  const [post] = useFetch("/blogs/add");
  const [images, setImages] = useState<FileList | null>();
  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Images", images as any);
    try {
      const res = await axios.post(
        "http://localhost:5000/blogs/add",
        { Images: images },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };
  const change = async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    if (e.target.files) setImages(e.target.files[0] as any);
    // await post(
    //   {
    //     data: {Images: e.target.files},
    //     message: "Uploaded Images!",
    //     headers: {enctype: "multipart/form-data"},
    //   },
    //   (res) => {
    //     console.log(res);
    //   }
    // );
  };
  return (
    <AdminNavbar
      sx={{
        minHeight: "100vh",
        display: "grid",
        alignItems: "center",
      }}
      disableFooter
    >
      <Stack mt={6} spacing={6}>
        <form onSubmit={submit}>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              name="images"
              id="contained-button-file"
              // multiple
              type="file"
              onChange={change}
            />
            <Action
              av="Button"
              type="button"
              label="Upload"
              startIcon={<PhotoCamera />}
              component="span"
              grid
            />
          </label>
          <input type="submit" />
        </form>
      </Stack>
    </AdminNavbar>
  );
}

export default memo(AdminBlogsPage);
