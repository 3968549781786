import { ReactElement, ReactNode, ChangeEvent, memo } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import useNavigate from "../../hooks/useNavigate";
import Footer from "../footer";
import { routeTypes } from "../../config";
import { Search, NavbarDrawerProps, NavbarDrawer } from ".";
import { SxType } from "..";

export interface NavbarProps extends NavbarDrawerProps {
  updateSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  endActions?: ReactNode;
  mobileEndActions?: ReactNode;
  title?: string;
  homeLink?: routeTypes;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "default"
    | "transparent"
    | undefined;
  disableFooter?: boolean;
  sx?: SxType;
}

function Navbar({
  children,
  updateSearch,
  endActions,
  mobileEndActions,
  title = "Cape2Care",
  homeLink = "/",
  color = "default",
  disableFooter,
  sx,
  ...drawerProps
}: WithChildren<NavbarProps>): ReactElement {
  const { navigate } = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        color={color}
        position="fixed"
        sx={{
          top: { xs: "auto", sm: 0 },
          bottom: { xs: 0, sm: "auto" },
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <NavbarDrawer {...drawerProps} />
            <Typography
              noWrap
              variant="h6"
              component="div"
              sx={{ mx: 2 }}
              onClick={() => navigate(homeLink)}
            >
              {title}
            </Typography>
            {updateSearch && <Search updateSearch={updateSearch} />}
            <Box sx={{ flexGrow: 1 }} />
            {endActions && (
              <Stack
                display={{ xs: "none", md: "flex" }}
                direction="row"
                alignItems="center"
                spacing={1.5}
              >
                {endActions}
              </Stack>
            )}
            {mobileEndActions && (
              <Stack
                sx={{ display: { xs: "flex", md: "none" } }}
                direction="row"
                alignItems="center"
                spacing={1.5}
              >
                {mobileEndActions}
              </Stack>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {children && (
        <Container
          component="main"
          maxWidth="xl"
          sx={{
            pt: 7,
            minHeight: "92vh",
            ...sx,
          }}
        >
          {children}
        </Container>
      )}
      {!disableFooter && <Footer />}
    </Box>
  );
}

export default memo(Navbar);