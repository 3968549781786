import { ReactElement, memo } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { NeedsList } from "../../constants";
import { LordIcon, SponsorButton } from "..";
import { Palette } from "../../config";

function Needs(): ReactElement {
  return (
    <Paper
      elevation={3}
      sx={{
        bgcolor: "background.paper",
        p: 2,
        mt: 10,
        mb: 10,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Stack alignItems="center" justifyContent="center" width="100%" mt={-0} marginBottom="1.5rem">
        <LordIcon
          delay={3000}
          icon="Needs"
          secondary={Palette.secondary?.main}
          style={{ maxWidth: "8rem" }}
        />
        <Typography variant="h3" component="h3" fontWeight={500} mt={1} mb={7}>
          Our sponsor needs
        </Typography>
      </Stack>
      <Grid container spacing={1}>
        {NeedsList.map((need) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Stack direction="row" spacing={0.8}>
              <Typography variant="body2">—</Typography>
              <Typography variant="body2">{need}</Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <SponsorButton label="Sponsor" />
      
    </Paper>
  );
}

export default memo(Needs);