import { ReactElement, useCallback, memo } from "react";
import useFetch from "../../hooks/useFetch";
import Form from "../custom/Form";

function ContactForm(): ReactElement {
  const [post, loading] = useFetch("/contact");
  const submit = useCallback(
    async (info) => await post({ data: info, message: "Message Sent!" }),
    [post]
  );
  return (
    <Form
      onSubmit={submit}
      loading={loading}
      initialData={{
        FullName: "",
        Email: "",
        Phone: "Not provided",
        Message: "",
      }}
      Fields={[
        { name: "FullName", label: "Name" },
        { name: "Email", sm: 6 },
        { name: "Phone", sm: 6, optional: true },
        { name: "Message", multiline: true },
      ]}
      submitLabel="Send"
      title="Get involved"
      size="small"
      maxWidth="xs"
      TitleProps={{ variant: "h4", textAlign: "start", mb: 1 }}
      GridProps={{ spacing: 1 }}
    />
  );
}
export default memo(ContactForm);