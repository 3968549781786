import { useState, useCallback } from "react";
import { InputEventProps } from "../components";

export default function useForm<T>(
  initialState: T
): [
  T,
  (key: keyof T, checked?: boolean) => (e: InputEventProps) => void,
  () => void
] {
  const [formState, setFormState] = useState<T>(initialState);

  const onChange = useCallback(
    (key: keyof T, checked?: boolean) => (e: InputEventProps) => {
      setFormState((prevFormState) => ({
        ...prevFormState,
        [key]: checked ? e.target.checked : e.target.value,
      }));
    },
    []
  );

  const reset = useCallback(() => setFormState(initialState), [initialState]);

  return [formState, onChange, reset];
}