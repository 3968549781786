import { ReactElement, useCallback, memo } from 'react';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useBoolean, useForm } from '../../../hooks';
import TableButtons from './Table.Buttons';
import { TableBaseProps } from '.';

export interface TableDataProps<GenericRow> extends TableBaseProps<GenericRow> {
    row: GenericRow;
    openAdd?: boolean;
    toggleAdd?: (condition: boolean) => void;
    add?: boolean;
}

function TableData<TableDataGenericRow>({
    add,
    row,
    columns,
    editableColumns,
    addHandler,
    openAdd,
    toggleAdd,
    editHandler,
    actionsPosition,
    actions
}: TableDataProps<TableDataGenericRow>): ReactElement {
    const [edit, toggleEdit] = useBoolean();
    const [submitData, onChange, reset] = useForm(row);
    const cancel = useCallback(() => {
        reset();
        toggleEdit(false);
        toggleAdd && toggleAdd(false);
    }, [reset, toggleAdd, toggleEdit]);
    return (
        <TableRow>
            {actionsPosition === 'start' && (
                <TableButtons
                    add={add}
                    row={row}
                    submitData={submitData}
                    open={add ? openAdd : edit}
                    toggle={add ? toggleAdd : toggleEdit}
                    clickHandler={add ? addHandler : editHandler}
                    actions={actions}
                />
            )}
            {columns.map((column, index) => (
                <TableCell
                    key={index}
                    padding={edit ? 'normal' : 'normal'}
                    component={
                        index === 0 &&
                        (actionsPosition === 'end' || !actions) &&
                        (!editHandler || actionsPosition === 'end')
                            ? 'th'
                            : 'td'
                    }
                    scope={
                        index === 0 &&
                        (actionsPosition === 'end' || !actions) &&
                        (!editHandler || actionsPosition === 'end')
                            ? 'row'
                            : undefined
                    }
                >
                    {(edit || add) && editableColumns?.includes(column) ? (
                        <TextField
                            type={
                                typeof row[column] === 'number'
                                    ? 'number'
                                    : 'text'
                            }
                            size='small'
                            margin='none'
                            variant='outlined'
                            fullWidth={false}
                            defaultValue={row[column]}
                            onChange={onChange(column)}
                            onKeyUp={e => e.key === 'Escape' && cancel()}
                            autoFocus={index === 0}
                            sx={{ maxWidth: 'fit-content' }}
                            multiline
                        />
                    ) : (
                        row[column]
                    )}
                </TableCell>
            ))}
            {actionsPosition === 'end' && (
                <>
                    <TableButtons
                        add={add}
                        row={row}
                        submitData={submitData}
                        open={add ? openAdd : edit}
                        toggle={add ? toggleAdd : toggleEdit}
                        clickHandler={add ? addHandler : editHandler}
                        actions={actions}
                    />
                    {!add && !editHandler && <TableCell padding='checkbox' />}
                </>
            )}
        </TableRow>
    );
}

export default memo(TableData) as typeof TableData;
