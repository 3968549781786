export const routeConfig = {
  baseServiceUrl: "https://api.cape2care.dwfc.org.za",
  baseHomeUrl: "https://cape2care.dwfc.org.za",
  // baseHomeUrl: 'https://dev.cape2care.dwfc.org.za',
};
export const payfastConfig = {
  PayfastLink: "https://www.payfast.co.za/eng/process",
  MerchantID: "18673619",
  MerchantKey: "sscpmm3toy87y",
};
export const console_log = (msg?: any, ...opt: any[]) => null;