import { useState, useCallback, ChangeEvent } from "react";
import useBoolean from "../../../hooks/useBoolean";
import { TableProps } from "./Table";

export default function useTable<TableGenericData>(
  props: TableProps<TableGenericData>
) {
  const [sortBy, setSortBy] = useState<keyof TableGenericData>(
    props.sortByDefault
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAdd, toggleAdd] = useBoolean();

  const descendingComparator = useCallback(
    (a: TableGenericData, b: TableGenericData) =>
      b[sortBy] < a[sortBy] ? -1 : b[sortBy] > a[sortBy] ? 1 : 0,
    [sortBy]
  );
  const getComparator = useCallback(
    (a: TableGenericData, b: TableGenericData) => {
      return props.disableSort
        ? 0
        : sortDirection === "desc"
        ? descendingComparator(a, b)
        : -descendingComparator(a, b);
    },
    [descendingComparator, sortDirection]
  );

  const onUpdateSort = useCallback(
    (newSortBy) => () => {
      setSortDirection(
        sortBy === newSortBy && sortDirection === "asc" ? "desc" : "asc"
      );
      setSortBy(newSortBy);
    },
    [sortBy, sortDirection]
  );

  const filterData = (item: TableGenericData) =>
    props.search
      ? JSON.stringify(item)
          .toLowerCase()
          .includes(props.search.toLowerCase()) && item
      : item;

  const changePageHandler = useCallback(
    (_event: unknown, newPage: number) => setPage(newPage),
    []
  );
  const changeRowsPerPageHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

  return {
    sortBy,
    sortDirection,
    page,
    rowsPerPage,
    openAdd,
    toggleAdd,
    getComparator,
    onUpdateSort,
    filterData,
    changePageHandler,
    changeRowsPerPageHandler,
    emptyRows,
  };
}
