import { ReactElement, memo } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';



function PreviousWalk2023(): ReactElement {


  return (
    <Stack
      alignItems='center'

      spacing={5}
    >
      <Typography
        variant="h2"
        component="h2"
        fontWeight={500}
        textAlign="center"
        mb={4}
      >
        Cape 2 Care 2022
      </Typography>
      <Stack spacing={3} mt={5} mx={2} alignItems="center">
        <Typography variant="body2" style={{ textAlign: "center" }}>
          We've walked for our children who have been failed, failed by their circumstances, failed by their adult figures, failed by their environment, failed by false promises, unaware of how empowered they actually are, and the ability they have to change their circumstances.<br /><br />

          We've walked for our Children with potential who just need the ONE opportunity to rise above their circumstances and change the failure into freedom.<br /><br />

          We've walked from Johannesburg to the Waterfront, Cape Town, Raising R400 000 for Dynamic Women for Children. We are beyond grateful for each and everyone who made this journey possible!<br /><br />

          We at Dynamic Women for Children used the money that we've raised with Cape2Care 2022 to do restorations and upgrade the Dynamic Women for Children premises, to create a saver space for our children and their futures.<br /><br />
        </Typography>
      </Stack>
      <Stack spacing={3} mt={5} mx={2} alignItems="center">
        <Stack direction={{ xs: "column", md: "row" }} spacing={6}>
          <Stack spacing={2}>
            <Typography variant="body2" >
              Cape 2 Care 2022 Sponsors:<br />
              -Groot Ontbyt <br />
              - Gys Pitzer Motoring <br />
              - The Local Choice Pharmacy Med Value <br />
              -Caring Daisies<br />
              - Nutribrands Nucleo Superfoods<br />
              - Vermelion Designs & Developers<br />
              -SunSkin<br />
              - Milc & Hunny<br />
              - Willa Krause Skin Care<br />
              - USN<br />
              - Oasis Water<br />
              - Tessa Cameron Sweat Gear<br />
              - GWM Haval<br />



            </Typography>
          </Stack>

          <Stack spacing={2}>
            <Typography variant="body2" style={{ textAlign: "left" }}>





              Cape 2 Care 2022 Accommodation: <br />

              - Domba executive suites <br />
              - Erkhamka Lodge<br />
              - Klipkappertjie Geust house and The Staffordshire pub <br />
              - Fox den and TrompOp Boerekos<br />
              - Safari Quaggasfontein Private Game Reserve <br />
              - Khoisan Karoo Conservancy <br />
              - Vrede & Rus Gastehuis<br />
              - Leeuwfontein Gaste Plaashuis<br />
              - Biesiespoort<br />
              - Tuishuis Gastehuis<br />
              - Sutherland Hotel<br />
              - Sadawa<br />
              - Fairfield Cottages <br />
              - Cresta Grande Cape Town <br /><br />
            </Typography>
          </Stack>
        </Stack>


        <Stack direction={{ xs: "column", md: "row" }} spacing={6}>
          <Stack spacing={2}>
            <Typography variant="body2" style={{ textAlign: "center" }}>
              {/* Every individual, family or company that made a donation towards Cape2Care 2022! <br /><br /> */}

              Cape 2 Care 2022 <br />
              We've walked for our children... <br /><br />

              "Let's raise children who won't have to recover from their childhoods."
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
export default memo(PreviousWalk2023);
