import { ReactElement, memo } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Plant from "../../assets/gifs/Plant.gif";
import useConfig from "../../context/useConfig";
import { Action, Progress, SponsorsContribute } from "..";
// import Video from "../assets/videos/Cape2Care.mp4";
import Video from "../../assets/videos/C2C-video.mp4";

function Sponsors(): ReactElement {
  const { dispatchConfig } = useConfig();
  return (

    <Stack my={7}
      alignItems="center"
      width="100%"
    >
      <div>
        <video src={Video} controls style={{ borderRadius: "10px", width: "100%", maxHeight: 360, maxWidth: 640 }}>
        </video>
      </div>
      <Typography
        variant="h2"
        component="h2"
        fontWeight={500}
        textAlign="center"
        marginTop="1em"

      >
        Kilometers Sponsored
      </Typography>
      <Box
        component="img"
        src={Plant}
        width="100%"
        maxWidth="40rem"
        alignSelf="center"
        m="3rem auto"
        my={3}
      />
      <Progress />
      <SponsorsContribute />
      <Action
        av="Button"
        label="Donate"
        to={{ path: "/donate" }}
        onClick={() => dispatchConfig({ type: "SET_DONATE_OPTION", option: 1 })}
        disableFullWidth
        sx={{ mt: 4 }}
      />
      <Typography
        variant="h5"
        component="h5"
        fontWeight={600}
        textAlign="center"
        paddingTop="3em"
      >
        EFT Payment Details:
      </Typography>
      <Typography
        // variant="h6"
        // component="h6"
        fontWeight={400}
        textAlign="center"
        paddingTop="1em"
      >
        FNB: Cape 2 Care <br />
        Account Number: 629 187 88334 <br />
        Branch Code: 230145 <br />
        Branch Name: Zambezi Drive <br />
        Reference: Name and Contact Number<br />
        <br />
        We are a registered Article 18A orginization!
      </Typography>
    </Stack>
  );
}

export default memo(Sponsors);