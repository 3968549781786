import { ReactElement, useEffect, useCallback, memo } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Hooks from '../hooks';
import useAuth, { ILogin } from '../context/useAuth';
import { Form, Action, PublicNavbar } from '../components';
import { publicRoutes } from '../config';
import { type_includes } from '../functions';

function LoginPage(): ReactElement {
    Hooks.useTabName('Cape2Care | Login');
    const { Role, setSession } = useAuth();
    const { navigate, from } = Hooks.useNavigate();
    const [post, loading] = Hooks.useFetch<ILogin>('/users/login');
    const [showPassword, togglePassword] = Hooks.useBoolean();

    useEffect(() => {
        Role !== null && navigate(from, { replace: true });
    }, [Role, from, navigate]);

    const loginHandler = useCallback(
        async values =>
            await post(
                {
                    data: values,
                    message: 'Login successful!'
                },
                res => {
                    if (res.result === 'success') {
                        setSession(res.data);
                        navigate(
                            type_includes(publicRoutes, from) ? '/admin' : from,
                            { replace: true }
                        );
                    }
                }
            ),
        [post, setSession, navigate, from]
    );

    return (
        <PublicNavbar sx={{ placeContent: 'center', display: 'grid' }}>
            <Form
                onSubmit={loginHandler}
                loading={loading}
                initialData={{
                    Email: '',
                    Password: ''
                }}
                Fields={[
                    { name: 'Email', type: 'email' },
                    {
                        name: 'Password',
                        type: showPassword ? 'text' : 'password',
                        endAdornment: (
                            <Action
                                av='IconButton'
                                label='toggle password'
                                Icon={
                                    showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )
                                }
                                onClick={togglePassword}
                            />
                        )
                    }
                ]}
                submitLabel='Login'
                title='Cape2Care Login'
            />
        </PublicNavbar>
    );
}

export default memo(LoginPage);
