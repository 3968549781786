import { ReactElement, memo } from "react";
import MuiTableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { TableBaseProps } from "./Table";
import TableSortLabel from "@mui/material/TableSortLabel";

export interface TableHeadProps<GenericData>
  extends TableBaseProps<GenericData> {
  sortBy: keyof GenericData;
  sortDirection: "asc" | "desc";
  onUpdateSort: (sortBy: keyof GenericData) => () => void;
}

function TableHead<TableHeadGenericData>(
  props: TableHeadProps<TableHeadGenericData>
): ReactElement {
  return (
    <MuiTableHead>
      <TableRow>
        {props.actionsPosition === "start" &&
          props.actions?.map(() => <TableCell padding="checkbox" />)}
        {props.actionsPosition === "start" && props.editHandler && (
          <TableCell padding="checkbox" />
        )}
        {props.columns.map((column, id) => (
          <TableCell
            key={id}
            // sortDirection={
            //   !props.sortColumns &&
            //   (props.sortBy === column ? props.sortDirection : false)
            // }
          >
            {props.sortColumns?.includes(column) ? (
              <TableSortLabel
                active={props.sortBy === column}
                direction={
                  props.sortBy === column ? props.sortDirection : "asc"
                }
                onClick={props.onUpdateSort(column)}
              >
                <Typography
                  // variant='subtitle2'
                  sx={{ textTransform: "capitalize" }}
                >
                  {typeof column === "string"
                    ? column
                        .replace(/([A-Z])/g, " $1")
                        .trim()
                        .replaceAll("_", " ")
                        .replaceAll("-", " ")
                    : column}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography
                // variant='subtitle2'
                sx={{ textTransform: "capitalize" }}
              >
                {typeof column === "string"
                  ? column
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .replaceAll("_", " ")
                      .replaceAll("-", " ")
                  : column}
              </Typography>
            )}
          </TableCell>
        ))}
        {props.actionsPosition === "end" &&
          props.actions?.map(() => <TableCell padding="checkbox" />)}
        {props.actionsPosition === "end" && props.editHandler && (
          <TableCell padding="checkbox" />
        )}
      </TableRow>
    </MuiTableHead>
  );
}

export default memo(TableHead) as typeof TableHead;
