import React from 'react';
import { AlertColor } from '@mui/material/Alert';

export type muiColorType =
    | AlertColor
    | 'inherit'
    | 'primary'
    | 'secondary'
    | undefined;

export type muiButtonVariantTypes =
    | 'text'
    | 'contained'
    | 'outlined'
    | undefined;

interface OptionProps {
    message?: any;
    color?: muiColorType;
    variant?: muiButtonVariantTypes;
    Icon?: React.ReactNode;
    action?: () => void;
}

export interface alertProps {
    open: boolean;
    message: any;
    severity?: AlertColor;
    denyOptions?: OptionProps;
    acceptOptions?: OptionProps;
}

export const alertInitialState: alertProps = {
    open: false,
    message: 'Successful',
    severity: 'success'
};

export type alertActionTypes =
    | { type: 'OPEN' }
    | { type: 'CLOSE' }
    | { type: 'TOGGLE' }
    | { type: 'UPDATE_ALERT'; message: any; severity: AlertColor }
    | { type: 'SET_DENY_OPTIONS'; options: OptionProps }
    | { type: 'SET_ACCEPT_OPTIONS'; options: OptionProps };

export interface alertReturnProps {
    options: alertProps;
    open: () => void;
    close: () => void;
    toggle: () => void;
    success: (msg?: string | undefined) => void;
    error: (msg?: string | undefined) => void;
    warning: (msg?: string | undefined) => void;
    info: (msg?: string | undefined) => void;
    dispatch: React.Dispatch<alertActionTypes>;
}

export type useAlertType = () => alertReturnProps;

export const useAlert: useAlertType = () => {
    const [alert, dispatch] = React.useReducer(
        (state: alertProps, action: alertActionTypes) => {
            switch (action.type) {
                case 'OPEN':
                    return { ...state, open: true };
                case 'CLOSE':
                    return { ...state, open: false };
                case 'TOGGLE':
                    return { ...state, open: !state.open };
                case 'UPDATE_ALERT':
                    return {
                        ...state,
                        message: action.message,
                        severity: action.severity
                    };
                default:
                    return state;
            }
        },
        alertInitialState
    );

    const open = () => dispatch({ type: 'OPEN' });
    const close = () => dispatch({ type: 'CLOSE' });
    const toggle = () => dispatch({ type: 'TOGGLE' });

    const success = (msg?: string) =>
        dispatch({
            type: 'UPDATE_ALERT',
            severity: 'success',
            message: msg ? msg : 'Success!'
        });

    const error = (msg?: string) =>
        dispatch({
            type: 'UPDATE_ALERT',
            severity: 'error',
            message: msg ? msg : 'Error!'
        });

    const info = (msg?: string) =>
        dispatch({
            type: 'UPDATE_ALERT',
            severity: 'info',
            message: msg ? msg : 'Info!'
        });

    const warning = (msg?: string) =>
        dispatch({
            type: 'UPDATE_ALERT',
            severity: 'warning',
            message: msg ? msg : 'Warning!'
        });

    return {
        options: alert,
        open,
        close,
        toggle,
        success,
        error,
        warning,
        info,
        dispatch
    };
};
