import { ReactElement, useState, useEffect, memo } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Backdrop from "@mui/material/Backdrop";

export interface LoadingProps {
  loading?: boolean;
  color?: Exclude<ColorOptions, "default">;
  backdrop?: boolean;
  relative?: boolean;
}

function Loading({
  loading,
  color,
  backdrop,
  relative,
  children,
}: WithChildren<LoadingProps>): ReactElement {
  const [loadingState, setLoadingState] = useState<boolean>(
    loading !== undefined ? loading : true
  );
  useEffect(
    () => setLoadingState(loading !== undefined ? loading : true),
    [loading]
  );
  return (
    <>
      {loadingState ? (
        backdrop ? (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loadingState}
          >
            <LinearProgress
              color={color ?? "primary"}
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
              }}
            />
          </Backdrop>
        ) : (
          <LinearProgress
            color={color ?? "primary"}
            sx={{
              position: relative ? "absolute" : "fixed",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100,
            }}
          />
        )
      ) : (
        children
      )}
    </>
  );
}

export default memo(Loading);
