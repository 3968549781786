import { createContext, useContext } from 'react';

export interface ISponsor {
    Guid?: string;
    FullName: string;
    Logo: string;
    Link?: string | null;
    Amount: number;
    DateAdded?: Date | string;
}
export interface IContributor {
    Guid?: string;
    FullName: string;
    Link?: string | null;
    Amount: number;
    DateAdded?: Date | string;
}
export interface SponsorsContextProps {
    sponsors: ISponsor[];
    contributors: IContributor[];
    sponsorsLoading: boolean;
    contributorsLoading: boolean;
    getSponsors: () => Promise<void>;
    getContributors: () => Promise<void>;
    addSponsor: (sponsor: ISponsor) => void;
    addContributor: (contributor: IContributor) => void;
    editSponsor: (sponsor: ISponsor) => void;
    editContributor: (contributor: IContributor) => void;
}

export const SponsorsContext = createContext<SponsorsContextProps>({
    sponsors: [],
    contributors: [],
    sponsorsLoading: true,
    contributorsLoading: true,
    getSponsors: async () => {},
    getContributors: async () => {},
    addSponsor: () => {},
    addContributor: () => {},
    editSponsor: () => {},
    editContributor: () => {}
});

export const useSponsors = () => useContext(SponsorsContext);
