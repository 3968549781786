import { Dispatch, createContext, useContext } from 'react';
import { ILogin } from '.';
import {
    authReducerInitialState,
    IAuthReducer,
    authActionTypes,
    ISession
} from './auth.reducer';

interface AuthContextProps extends IAuthReducer {
    auth: IAuthReducer;
    session: ISession;
    dispatchAuth: Dispatch<authActionTypes>;
    setSession: (Login: ILogin) => void;
    updateSession: (role: RoleTypes) => void;
    logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>({
    ...authReducerInitialState,
    auth: authReducerInitialState,
    session: {
        MemberGuid: authReducerInitialState.MemberGuid,
        SessionGuid: authReducerInitialState.SessionGuid
    },
    dispatchAuth: () => {},
    setSession: () => {},
    updateSession: () => {},
    logout: async () => {}
});

export const useAuth = () => useContext(AuthContext);
