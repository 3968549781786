import MichelleProfile from "../assets/images/profiles/Michelle.jpg";
import MarleenProfile from "../assets/images/profiles/Marleen.jpg";
// import LouiseProfile from "../assets/images/profiles/Louise.jpg";
// import MarinaProfile from "../assets/images/profiles/Marina du Plooy.jpg";
// import HanliProfile from "../assets/images/profiles/Hanli Reinecke.jpg";
// import PieterProfile from "../assets/images/profiles/Pieter Gouws.jpg";
// import IdaProfile from "../assets/images/profiles/Ida Degenaar.jpg";
import RikalizeProfile from "../assets/images/profiles/Rikalize Vosloo.jpg";
import RinaProfile from "../assets/images/profiles/Rina Prinsloo.jpg";
import MichelleRProfile from "../assets/images/profiles/Michelle Roosenschoon.jpg";
import MarlienProfile from "../assets/images/profiles/Marlien Cronje.jpg";
import KylaProfile from "../assets/images/profiles/Kyla van Biljon.jpg";
import MarisiaProfile from "../assets/images/profiles/Marisia.jpeg";

export interface IWhyWeWalk {
  message: string;
  profile: string;
}

export const WhyWeWalkConstant: { [key: string]: IWhyWeWalk } = {
  Michelle_van_Biljon: {
    message: `I walk for our children who have been failed, failed by their circumstances, failed by their adult figures, failed by their environment, failed by false promises, unaware of how empowered they actually are, and the ability they have to change their circumstances. I walk for our Children with potential who just need the ONE opportunity to rise above their circumstances and change the failure into freedom.`,
    profile: MichelleProfile,
  },
  Marleen_van_der_Walt: {
    message: `My whole life I had this feeling that I wanted to do something significant, to make an impact - not only in my life, but in the lives of others. I want to walk for children - for their dreams, for their furture, for children that don't have a voice. I also see this opportunity as a Spirtual journey, to grow in my faith and to come closer to God and reconnecting my strength in my faith`,
    profile: MarleenProfile,
  },
  // Louise_Mostert: {
  //   message: `My older sister passed away a few days ago at a reasonable young age.  She was a Social Worker who loved to work with kids in a broken world.  She loved running marathons with her friends and taking hikes in the mountains etc.  So, this year (2023) I dedicate my journey to Annelize!  What Annelize did as a profession, the reason why she did it and her love for the outdoors and running coincide with what I love to do and the reason I love to do it, even if I don’t have a social work degree.  So, my sister and I share the same vision that Marleen has and as I see Marleen as a very dear friend of mine, I told her that I would always love to undertake something like this, with her, and that is why this is my second and definitely not my last Cape2Care love walk/fundraising walk.`,
  //   profile: LouiseProfile,
  // },
  // Marina_du_Plooy: {
  //   message: `I'm doing this walk to raise funds and awareness for under privileged children.

  //   We want to help ease the burden by making their lives a little better and their futures a little brighter. 

  //   By doing this walk we would be able to help them to attend school, by supplying school necessities like uniforms and stationary. 

  //   I am doing this walk because I am able to make a difference to people and children who have seen and felt the worst life can give them. I want to be a light in a dark tunnel, I want to be a smile on someone's face, I want to make a difference.

  //   I am also doing this walk to prove to myself that I am a strong woman, I am able to overcome any obstacle that crosses my path. I know this will push my limits but at the end I will come out stronger mentally, emotionally and physically. `,
  //   profile: MarinaProfile,
  // },
  // Hanli_Reinecke: {
  //   message: `To be able to really live, you should have the courage to draw without an eraser.
  //   My purpose with every step of the journey, is to generate means to teach children to have that courage every day of their lives.  My personal goal is to step up to the challenge and through that be an inspiration to others to endure and succeed. `,
  //   profile: HanliProfile,
  // },
  // Ida_Degenaar: {
  //   message: `My passion was to walk the Portuguese Camino when Covid kept everyone home. And then I realized that I can walk the walk for a purpose. And the purpose is our children. Marleen & Michelle became the anchor for so many children. Giving them hope for the future. And that made me decide to walk with them. Every single step for a broken child. And healing through people like Dynamic Women for Children. I salute you!  `,
  //   profile: IdaProfile,
  // },
  // Pieter_Gouws: {
  //   message: `I Feel that i need to be part of the roadside support and the safety for these brave ladies during their walk for children in need. All of us has a purpose to be on earth and we need to do everything we can to help children in need. This is my small contribution to be driving the support vehicle during this important walk for children.
  //   `,
  //   profile: PieterProfile,
  // },
  Rikalize_Vosloo: {
    message: `I walk for the dreams of a child. I walk to support and restore faith in a child's heart, failed by circumstances. I walk to convey hope and God's faithfulness. I walk to raise children who won't have to recover from their childhoods. Every step of the journey is dedicated to the single opportunity that would allow so many children to rise above their circumstances, to create a future full of change. One of my childhood best friends is supported by Dynamic Women for Children. Seeing the wonderful woman she has become through the support, love, and endless possibilities they have provided her creates hope for so many children.`,
    profile: RikalizeProfile,
  },
  Rina_Prinsloo: {
    message: `As a mother of 4, with children between the ages of 10 and 18, I have gone through various stages with my children and learned that there are so many things that can influence them, both good and bad. The little that I can give to help, not just my own children, but also the other children who are part of Dynamic Women for Children, is to raise funds for people like Marleen and Michelle, to support and empower them, and to assist the people who are involved in our children's lives in making a difference with their love, care, and understanding of who they are and where they are going. Therefore, I would love to be a part of this journey, raising funds, praying, and uplifting not just the children but also families.`,
    profile: RinaProfile,
  },
  Michelle_Roosenschoon: {
    message: `The day I was asked to join the walk, it wasn't a difficult decision to make. I almost immediately knew my answer. I was a bit nervous about getting fit enough in 3, almost 4 months, but there has been a lot of support and encouragement. Dynamic Women for Children has changed my life, and this is a way for me to make a difference and help all the beautiful children I have gotten to know during my time there. This journey will also help me discover my limits and what I am capable of.`,
    profile: MichelleRProfile,
  },
  Marlien_Cronje: {
    message: `I feel privilege to be part of this journey with these ladies for our children. By giving we create hope and all people need is hope to believe in today and tomorrow with the miracles of God. I am walking to stand in for the children that was disappointed by their adults for them to know there are adults that care. I am walking for myself to journey with God and  set a mind that is unstoppable.`,
    profile: MarlienProfile,
  },
  Kyla_van_Biljon: {
    message: `I walk for the children that feel like they don't have a voice. I walk for the children that deserves more than their current circumstances. I hope that the children discover that they are loved and that there is a whole world out there made with a place just for them. I see this as opportunity to get to know myself better as well as a Spirtual journey. To grow to love myself and to grow in my faith. I believe that every step is a step that carries weight.`,
    profile: KylaProfile,
  },
};

export type whyWeWalkType = typeof WhyWeWalkConstant;
