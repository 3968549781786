import { ReactElement, useState, useEffect, memo } from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useFetch, useNavigate } from "../../hooks";
import Blog, { IBlog } from "./Blog";
import dummy_data from "./dummy_data";

function Blogs(): ReactElement {
  const { path } = useNavigate();
  const [blogs, setBlogs] = useState<any>(dummy_data);
  // const [get, loading] = useFetch('/blogs/all');
  const [page, setPage] = useState(false);
  let blog: any = [];

  // useEffect(() => {
  //     const getBlogs = async () => {
  //         // const result = await get(path === '/' ? { ext: '/3' } : undefined);
  //         const result = await get();
  //         // result.data.map((blog: any) =>
  //         //     console.log('RESULT', JSON.parse(blog.image_data))
  //         // );
  //         setBlogs(result.data);
  //     };
  //     // axios
  //     //     .get(`${routeConfig.baseServiceUrl}/blogs/all`)
  //     //     .then(res => res.data.map(blog => JSON.parse(blog.image_data)));
  //     getBlogs();
  // }, [get, path]);

  useEffect(() => setPage(path === "/blogs"), [path]);

  return (
    <Stack
      my={14}
      direction="row"
      columnGap={page ? 8 : 4}
      rowGap={page ? 8 : 4}
      flexWrap="wrap"
      justifyContent="center"
    >
      {blogs.map((blog: IBlog) => (
        <Blog key={blog.id} {...blog} />
      ))}
    </Stack>
  );
}

export default memo(Blogs);