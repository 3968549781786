import { ReactElement, memo } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { WhishList } from "../../constants";
import { Action, LordIcon } from "..";
import { Palette } from "../../config";
import Image1 from "./child_protection_hotline/1.jpg";
import Image2 from "./child_protection_hotline/2.jpg";
import Image3 from "./child_protection_hotline/3.jpg";
import Image4 from "./child_protection_hotline/4.jpg";
import Image5 from "./child_protection_hotline/5.jpg";
import Image6 from "./child_protection_hotline/6.jpg";
import Image7 from "./child_protection_hotline/7.jpg";
import Image8 from "./child_protection_hotline/8.jpg";
import Image9 from "./child_protection_hotline/9.jpg";
import Image10 from "./child_protection_hotline/10.jpg";
import Image11 from "./child_protection_hotline/11.jpg";
import Image12 from "./child_protection_hotline/12.jpg";
import Image13 from "./child_protection_hotline/13.jpg";
import Image14 from "./child_protection_hotline/14.jpg";
import Image15 from "./child_protection_hotline/15.jpg";
import Image16 from "./child_protection_hotline/16.jpg";
import Image17 from "./child_protection_hotline/17.jpg";

function Wishlist(): ReactElement {
  return (
    <Stack alignItems="center" width="100%" my={7}>
      <Typography
        variant="h2"
        component="h2"
        fontWeight={500}
        textAlign="center"
        marginTop="0.5em"
        mb={3}
      >
        Our Charity Needs
      </Typography>
      <Grid container spacing={4} alignItems="stretch" width="100%">
        {Object.keys(WhishList).map((key) => (
          <Grid item xs={12} sm={6} lg={3} mt={4}>
            <Paper
              key={key}
              elevation={3}
              sx={{
                bgcolor: "background.paper",
                p: 2,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LordIcon
                icon={WhishList[key].icon}
                delay={3000}
                secondary={Palette.primary?.main}
                style={{
                  maxWidth: "8rem",
                  alignSelf: "center",
                  // marginTop: "-5rem",
                }}
              />
              <Typography variant="h4" component="h4" fontWeight={500}>
                {key.replaceAll("_", " ")}
              </Typography>
              <Stack spacing={0.5} mt={1}>
                {WhishList[key].needs.map((need) => (
                  <Stack direction="row" spacing={0.8}>
                    <Typography variant="body2">—</Typography>
                    <Typography variant="body2">{need}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Action
        av="Button"
        label="Make a difference"
        sx={{ mt: 4 }}
        to={{ path: "/donate" }}
        disableFullWidth
      />
    <Typography
                    variant="h2"
                    component="h2"
                    fontWeight={500}
                    textAlign="center"
                    marginTop="1em"
                    mb={4}
                >
                    National Child Protection Hotlines:
                </Typography>
    <Grid container spacing={8} 
    justifyContent="center" 
    width="100%"
    marginTop="1em"
    margin="auto"    >
    <Box
        component="img"
        src={Image1}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={3}
    /> <Box
        component="img"
        src={Image2}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={3}
    /> <Box
        component="img"
        src={Image3}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={3}
    /> <Box
        component="img"
        src={Image4}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={1}
    /> <Box
        component="img"
        src={Image5}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={3}
    />
    <Box
        component="img"
        src={Image6}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={1}
    />
    <Box
        component="img"
        src={Image7}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={1}
    />
    <Box
        component="img"
        src={Image8}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={1}
    />
    <Box
        component="img"
        src={Image9}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={1}
    />
    <Box
        component="img"
        src={Image10}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={1}
    />

    <Box
        component="img"
        src={Image11}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={1}
    />
    <Box
        component="img"
        src={Image12}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={1}
    />
    <Box
        component="img"
        src={Image13}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={1}
    />
    <Box
        component="img"
        src={Image14}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={1}
    />
    <Box
        component="img"
        src={Image15}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={3}
    />

    <Box
        component="img"
        src={Image16}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={1}
    />


    <Box
        component="img"
        src={Image17}
        width="100%"
        maxWidth="15rem"
        alignSelf="center"
        m="1.1rem"
        my={1}
    />
    </Grid>
    </Stack>
  );
}
export default memo(Wishlist);