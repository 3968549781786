import { ReactElement, useMemo, memo } from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { PreviousWalk } from "../components";
import { PreviousWalkConstant } from "../constants/PreviousWalk.Constant";
import Grid from '@mui/material/Grid';
import PublicNavbar from "../components/navbar/Public.Navbar";
import Image1 from "./2022_walk/1.jpg";
import Image2 from "./2022_walk/2.jpg";
import Image3 from "./2022_walk/3.jpg";
import Image4 from "./2022_walk/4.jpg";
import Image5 from "./2022_walk/5.jpg";
import Image6 from "./2022_walk/6.jpg";
import Image7 from "./2022_walk/7.jpg";
import Image8 from "./2022_walk/8.jpg";
import Image9 from "./2022_walk/9.jpg";
// import Image10 from "./2022_walk/10.jpg";
import Image11 from "./2022_walk/11.jpg";
import Image12 from "./2022_walk/12.jpg";
import Image13 from "./2022_walk/13.jpg";
import Image14 from "./2022_walk/14.jpg";
import Image15 from "./2022_walk/15.jpg";
import Image16 from "./2022_walk/16.jpg";
import Image17 from "./2022_walk/17.jpg";
import Image18 from "./2022_walk/18.jpg";
import Image19 from "./2022_walk/19.jpg";
import Image20 from "./2022_walk/20.jpg";
import Image21 from "./2022_walk/21.jpg";
import Image22 from "./2022_walk/22.jpg";
import Image23 from "./2022_walk/23.jpg";
import Image24 from "./2022_walk/24.jpg";
import Image25 from "./2022_walk/25.jpg";
// import Image26 from "./2022_walk/26.jpg";
import Image27 from "./2022_walk/27.jpg";
import Image28 from "./2022_walk/28.jpg";
import Image29 from "./2022_walk/29.jpg";
import Image30 from "./2022_walk/30.jpg";
import Image31 from "./2022_walk/31.jpg";
import Image32 from "./2022_walk/32.jpg";
// import Image33 from "./2022_walk/33.jpg";
// import Image34 from "./2022_walk/34.jpg";
// import Image35 from "./2022_walk/35.jpg";
// import Image36 from "./2022_walk/36.jpg";


function PreviousWalkPage(): ReactElement {
    const memoizedSponsors = useMemo(() => PreviousWalkConstant, []);
  return (
    <PublicNavbar sx={{ pb: 6 }}>
   <Stack spacing={3} mt={5} mx={2} alignItems="center">
        <Typography
          variant="h2"
          component="h2"
          fontWeight={500}
          textAlign="center"
          mb={4}
        >
          Cape 2 Care 2022
        </Typography>

        <Typography  variant="body2" style={{ textAlign:"center"}}> 
We've walked for our children who have been failed, failed by their circumstances, failed by their adult figures, failed by their environment, failed by false promises, unaware of how empowered they actually are, and the ability they have to change their circumstances.<br /><br />

We've walked for our Children with potential who just need the ONE opportunity to rise above their circumstances and change the failure into freedom.<br /><br />

We've walked from Johannesburg to the Waterfront, Cape Town, Raising R400 000 for Dynamic Women for Children. We are beyond grateful for each and everyone who made this journey possible!<br /><br />

We at Dynamic Women for Children used the money that we've raised with Cape2Care 2022 to do restorations and upgrade the Dynamic Women for Children premises, to create a saver space for our children and their futures.<br /><br />
                </Typography>
                <Grid container spacing={8} justifyContent="center" width="100%">
                <Box
          component="img"
          src={Image1}
          width="100%"
          maxWidth="20rem"
          alignSelf="center"
          m="1rem auto"
          my={3}
        /> <Box
        component="img"
        src={Image2}
        width="100%"
        maxWidth="20rem"
        alignSelf="center"
        m="1rem auto"
        my={3}
      /> <Box
      component="img"
      src={Image3}
      width="100%"
      maxWidth="20rem"
      alignSelf="center"
      m="1rem auto"
      my={3}
    /> <Box
  component="img"
  src={Image4}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> <Box
     component="img"
    src={Image5}
       width="100%"
   maxWidth="20rem"
    alignSelf="center"
  m="1rem auto"
    my={3}
        />
        <Box
  component="img"
  src={Image6}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image7}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image8}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 

{/* <Box
  component="img"
  src={Image10}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/>  */}
<Box
  component="img"
  src={Image11}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image12}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 

<Box
  component="img"
  src={Image14}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image4}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image15}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image16}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
    component="img"
    src={Image4}
    width="100%"
    maxWidth="20rem"
    alignSelf="center"
    m="1rem auto"
    my={3}
  /> 

<Box
  component="img"
  src={Image18}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 


<Box
  component="img"
  src={Image21}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 

<Box
  component="img"
  src={Image23}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image24}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image25}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
{/* <Box
  component="img"
  src={Image26}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/>  */}
<Box
  component="img"
  src={Image27}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image28}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image29}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image30}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image31}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image32}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 

<Box
  component="img"
  src={Image17}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image20}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image9}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image13}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image22}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image19}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
{/* <Box
  component="img"
  src={Image33}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image34}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image35}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/> 
<Box
  component="img"
  src={Image36}
  width="100%"
  maxWidth="20rem"
  alignSelf="center"
  m="0rem auto"
  my={1}
/>  */}
      </Grid>
              </Stack>
              <Stack spacing={3} mt={5} mx={2} alignItems="center">
              <Stack direction={{ xs: "column", md: "row" }} spacing={6}>
          <Stack spacing={2}>
          <Typography  variant="body2" style={{ textAlign:"left",color:"#e43f83"}}>Cape 2 Care 2022 Sponsors:<br /><br /></Typography>
                <Typography  variant="body2" >
             
             -Groot Ontbyt <br />
             - Gys Pitzer Motoring <br />
             - The Local Choice Pharmacy Med Value <br />
             -Caring Daisies<br />
             - Nutribrands Nucleo Superfoods<br />
             - Vermelion Designs & Developers<br />
             -SunSkin<br />
             - Milc & Hunny<br />
             - Willa Krause Skin Care<br />
             - USN<br />
             - Oasis Water<br />
             - Tessa Cameron Sweat Gear<br />
             - GWM Haval<br />
             
      
    
                             </Typography>
                             </Stack>
                     
          <Stack spacing={2}>

          <Typography  variant="body2" style={{ textAlign:"left",color:"#e43f83"}}>
             

    
             
         
             Cape 2 Care 2022 Accommodation: <br /><br />
</Typography>
                             <Typography  variant="body2" style={{ textAlign:"left"}}>
             

    
             
         
                      
             
             - Domba executive suites <br />
             - Erkhamka Lodge<br />
             - Klipkappertjie Geust house and The Staffordshire pub <br />
             - Fox den and TrompOp Boerekos<br />
             - Safari Quaggasfontein Private Game Reserve <br />
             - Khoisan Karoo Conservancy <br />
             - Vrede & Rus Gastehuis<br />
             - Leeuwfontein Gaste Plaashuis<br />
             - Biesiespoort<br />
             - Tuishuis Gastehuis<br />
             - Sutherland Hotel<br />
             - Sadawa<br />
             - Fairfield Cottages <br />
             - Cresta Grande Cape Town <br /><br />
                             </Typography>
                             </Stack> 
              </Stack>
         
              <Grid
            container
            spacing={4}
          
            mx='auto'
            justifyContent='center'
            position='relative'
            width='100%'
        >
            <Grid item xs={12} textAlign='center'>
                <Typography variant='h5' fontWeight={600}>
                    Contributors 2022
                </Typography>
            </Grid>
           
                {/* {contributors.map(contributor => ( */}
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Anonymous
                        </Typography>
                    </Grid>
                      <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Y N Tomlinson
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Retha Lombard
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Agny vd Erve
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        JP Kleinhans
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Anoniem
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Johan de Hart
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Dr. Michelle van Tonder
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Sylvia
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Tercia
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Minnie Mostert
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Leon
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Annie Brent
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        F de Hart
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Fanus
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Marthinus Heystek
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Johannes Niekerk
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Marissa Maritz
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Bernice Howitz
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Hester Le Maitre
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Charlotte Andrag
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Pieter Gouws
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Elisie Styger
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Christel Snyman
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Victoria Wes
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Hennie & Maidelene
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Y. Bruwer
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Lubbe B.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Strydom M.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Elmarie Viljoen
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        DECALS & SIGNS CC
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Hennie
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Johan Schalkwyk
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        K Basson
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Richmond R
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Marietjie Jacobs
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Bekker
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Bekker
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Jan Hestek
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Mbuyisa
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Blessing
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        J du Preez
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Elaine
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Richmond
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Gwk SZ Gauteng
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Dorkas Premiermyn
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Fredi-Sylvia
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        A Retief
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        JF Jordaan
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Anonymous
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Danie Calitz
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Marinda Posthumus
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Andre De Goede
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Analise Nel
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Floris van der Walt
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Retha
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Annemarie Janse van Rensburg
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Melanie Du Plessis
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Jean Badenhorst
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Filters
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Filter Dienste Bethal
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        PJ Gouws
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Stofberg
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Gereformeerde kerk Kameeldrift Susters
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        M Evans
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Milanie Stadler
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Kobus van der Merwe
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        P de Vries
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Ouma & Oupa van Heerden
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Vermelion (PTY) Ltd
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Sylvia
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Stephanie Bosch
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Van Vredens
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Louise Maritz
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Jaco de Waal
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Dr. C Blignaut
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Salome
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Louise Mostert
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Tana
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Tasja Visser
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Sarona Pretorius
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Annique M Mostert
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Nanette le Roux
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Sanet
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        B More
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                        Lisinda de Hart
                        </Typography>
                    </Grid>
                   
                {/* ))} */}
         
        </Grid>
          <Stack  mt={5} direction={{ xs: "column", md: "row" }} spacing={6}>
          <Stack spacing={2}>
          <Typography  variant="body2" style={{ textAlign:"center",}}>
          {/* Every individual, family or company that made a donation towards Cape2Care 2022! <br /><br /> */}
         
          Cape 2 Care 2022 <br />
          We've walked for our children... <br /><br />
             
          "Let's raise children who won't have to recover from their childhoods."
                             </Typography>
                             </Stack> 
                             </Stack>
              </Stack>
    </PublicNavbar>
  );
}
export default memo(PreviousWalkPage);