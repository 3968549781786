import { ReactElement, ReactNode, useCallback, memo } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import Action from "../custom/action";
import { useBoolean, useNavigate } from "../../hooks";
import { routeTypes } from "../../config";

interface ILink {
  label: string;
  to?: routeTypes;
  href?: string;
  icon?: ReactNode;
  onClick?: () => void;
}

export interface NavbarDrawerProps {
  links: ILink[];
  secondaryLinks?: ILink[];
  anchor?: "left" | "top" | "right" | "bottom" | undefined;
}

function NavbarDrawer({
  anchor = "left",
  links,
  secondaryLinks,
}: NavbarDrawerProps): ReactElement {
  const [open, toggle] = useBoolean();
  const { navigate } = useNavigate();
  const closeHandler = useCallback(() => toggle(false), [toggle]);
  const openHandler = useCallback(() => toggle(true), [toggle]);
  return (
    <>
      <Action
        av="IconButton"
        Icon={<MenuIcon />}
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={openHandler}
      />
      <SwipeableDrawer
        anchor={anchor}
        open={open}
        onClose={closeHandler}
        onOpen={openHandler}
      >
        <Box
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
          }}
          role="presentation"
          onClick={closeHandler}
          onKeyDown={closeHandler}
        >
          <List>
            {links.map((link, index) => (
              <ListItem
                button
                key={link.to ?? link.href ?? index}
                onClick={() => link.to && navigate(link.to)}
                href={link.href ?? ""}
              >
                {link.icon && <ListItemIcon>{link.icon}</ListItemIcon>}
                <ListItemText primary={link.label} />
              </ListItem>
            ))}
          </List>

          {secondaryLinks && (
            <>
              <Divider />
              <List>
                {secondaryLinks.map((link, index) =>
                  link.href ? (
                    <a href={link.href}>
                      <ListItem button key={link.href}>
                        {link.icon && <ListItemIcon>{link.icon}</ListItemIcon>}
                        <ListItemText primary={link.label} />
                      </ListItem>
                    </a>
                  ) : (
                    <ListItem
                      button
                      key={link.to ?? index}
                      onClick={() =>
                        link.to
                          ? navigate(link.to)
                          : link.onClick && link.onClick()
                      }
                    >
                      {link.icon && <ListItemIcon>{link.icon}</ListItemIcon>}
                      <ListItemText primary={link.label} />
                    </ListItem>
                  )
                )}
              </List>
            </>
          )}
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default memo(NavbarDrawer);