import { ReactElement, memo } from "react";
import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Action from "../action";

interface ToolbarProps<GenericData> {
  title?: string;
  data: GenericData[];
  add: boolean;
  disableBadge?: boolean;
  toggleAdd: (condition?: any) => void;
  addHandler?: (row: GenericData) => void;
}

function Toolbar<ToolbarGenericData>({
  title,
  data,
  add,
  disableBadge,
  toggleAdd,
  addHandler,
}: ToolbarProps<ToolbarGenericData>): ReactElement {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      spacing={1.5}
      p={2}
    >
      {disableBadge ? (
        <Typography
          variant="h4"
          component="h4"
          fontWeight={500}
          sx={{ wordWrap: "normal" }}
          flex={1}
        >
          {title ?? "Optimised Table"}
        </Typography>
      ) : (
        <Badge badgeContent={data.length} color="primary">
          <Typography
            variant="h4"
            component="h4"
            fontWeight={500}
            sx={{ wordWrap: "normal" }}
            flex={1}
          >
            {title ?? "Optimised Table"}
          </Typography>
        </Badge>
      )}
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={3}
      >
        {addHandler && (
          <Action
            av="Button"
            Icon={add ? <Close /> : <Add />}
            color="secondary"
            variant="contained"
            size="small"
            sx={{ minWidth: "fit-content", maxWidth: "fit-content" }}
            onClick={toggleAdd}
            disableFullWidth
          />
        )}
      </Stack>
    </Stack>
  );
}

export default memo(Toolbar) as typeof Toolbar;
