import { ReactElement, useRef, useEffect, memo } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DonnationIcon from '../../assets/images/donation_icon.png';
import ContactIcon from '../../assets/images/contact.png';
import { WithButtonProps, Footer, Action, SxType } from '..';
import { useDimensions, useNavigate } from '../../hooks';

function PublicNavbar({
    disableFooter,
    sx,
    children
}: WithChildren<{
    disableFooter?: boolean;
    sx?: SxType;
}>): ReactElement {
    const topRef = useRef<HTMLElement>(null);
    const { path } = useNavigate();
    const { width } = useDimensions();
    useEffect(() => topRef.current?.scrollIntoView(), [path]);
    return (
        <>
            <Box
                component='nav'
                position='fixed'
                top={{ xs: 'auto', md: 0 }}
                bottom={{ xs: 0, md: 'auto' }}
                left={0}
                right={0}
                p={2}
                zIndex={10}
                bgcolor='background.default'
            >
                <Container maxWidth='xl' disableGutters>
                    <Stack
                        direction='row'
                        justifyContent='center'
                        spacing={1.5}
                    >
                        <NavLink to={{ path: '/' }} label='Home' />
                        <NavLink
                            to={{ path: '/why-we-walk' }}
                            label={width < 600 ? 'Purpose' : 'Why we Walk'}
                        />
                        <NavLink
                            href='https://www.cape2care.dwfc.org.za/cape2care-blogs/'
                            label={width < 600 ? 'Blog' : 'Blogs'}
                        />
                        <NavLink
                            to={{ path: '/previous-walk' }}
                            label={width < 600 ? '2022' : 'Cape 2 Care 2022'}
                        />
                        <NavLink
                            to={{ path: '/previous-walk-2023' }}
                            label={width < 600 ? '2023' : 'Cape 2 Care 2023'}
                        />
                        <NavLink
                            to={{ path: '/donate' }}
                            label='Donate'
                            Icon={
                                width < 500 && (
                                    <img
                                        src={DonnationIcon}
                                        alt={DonnationIcon}
                                        style={{ maxWidth: '1.8rem' }}
                                    />
                                )
                            }
                            variant={width < 500 ? 'text' : 'contained'}
                            color='primary'
                            sx={{ minWidth: 'fit-content' }}
                        />
                        <NavLink
                            href='#contact'
                            label='Get Involved'
                            variant={width < 500 ? 'text' : 'outlined'}
                            color='primary'
                            Icon={
                                width < 500 && (
                                    <img
                                        src={ContactIcon}
                                        alt={ContactIcon}
                                        style={{ maxWidth: '1.8rem' }}
                                    />
                                )
                            }
                            sx={{ minWidth: 'fit-content' }}
                        />
                    </Stack>
                </Container>
            </Box>
            {children && (
                <Container
                    component='main'
                    maxWidth='xl'
                    sx={{
                        bgcolor: 'background.default',
                        pt: { xs: 3, md: 7 },
                        minHeight: '92vh',
                        ...sx
                    }}
                    ref={topRef}
                >
                    {children}
                </Container>
            )}
            {!disableFooter && <Footer mb={width > 600 ? 0 : 9} />}
        </>
    );
}

function NavLink(props: WithButtonProps): ReactElement {
    return (
        <Action
            av='Button'
            disableFullWidth
            variant='text'
            size='small'
            color='inherit'
            activeColor='secondary'
            {...props}
        />
    );
}

export default memo(PublicNavbar);
