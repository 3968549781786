import { ReactElement, useEffect, useMemo, memo } from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { routes } from './config';
import { useFetch, useNavigate } from './hooks';
import useAuth from './context/useAuth';
import { NotFound } from './pages';

function RequireAuth({
    requiredRoles,
    children
}: {
    requiredRoles: RoleTypes[];
    children: ReactElement;
}) {
    const { Role } = useAuth();
    const { location } = useNavigate();

    if (!requiredRoles.includes(Role)) {
        return <Navigate to='/login' state={{ from: location }} />;
    }
    return children;
}

function Routes(): ReactElement {
    const { path } = useNavigate();
    const { session, updateSession, logout } = useAuth();
    const [post] = useFetch<{ Name: RoleTypes }>('/users/checkSession');
    const memoizedRoutes = useMemo(() => routes, []);
    useEffect(() => {
        const utCheckSession = async () =>
            await post({ data: session }, res =>
                res.result === 'success'
                    ? updateSession(res.data.Name)
                    : logout()
            );
        path === '/login' &&
            session.MemberGuid &&
            session.SessionGuid &&
            utCheckSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);
    return (
        <RouterRoutes>
            {memoizedRoutes.public.map(route => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                />
            ))}
            {memoizedRoutes.admin.map(route => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={
                        <RequireAuth requiredRoles={['ADMIN', 'SYSADMIN']}>
                            {route.element}
                        </RequireAuth>
                    }
                />
            ))}
            <Route path='*' element={<NotFound />} />
        </RouterRoutes>
    );
}
export default memo(Routes);
