import { ReactElement, useRef, useState, useCallback, memo } from "react";
import { useFetch } from "../../hooks";
import { Form, Payfast } from "..";

const donateMoneyIntitial = {
  FullName: "anonymous",
  Email: "",
  Message: "any",
  Amount: "0",
};

function DonateMoney(): ReactElement {
  const payfastRef = useRef<HTMLInputElement>(null);
  const [post, loading] = useFetch("/contact/donate-money");
  const [amount, setAmount] = useState(0);
  const submit = useCallback(
    async (info: typeof donateMoneyIntitial) => {
      setAmount(parseFloat(info.Amount));
      await post(
        {
          data: info,
          message: "Please complete payment in payfast",
        },
        ({ result }) => result === "success" && payfastRef.current?.click()
      );
    },
    [post]
  );
  return (
    <>
      <Form
        initialData={donateMoneyIntitial}
        loading={loading}
        onSubmit={submit}
        Fields={[
          { name: "FullName", optional: true },
          { name: "Email", sm: 6, optional: true },
          { name: "Amount", sm: 6 },
          { name: "Message", multiline: true, optional: true },
        ]}
      />
      <Payfast
        price={amount}
        name="Cape2Care Donation"
        path="/"
        payfastRef={payfastRef}
      />
    </>
  );
}

export default memo(DonateMoney);