import { Dispatch, createContext, useContext } from 'react';
import {
    configReducerInitialState,
    configActionTypes,
    IConfigReducer
} from './config.reducer';

export interface ConfigContextProps extends IConfigReducer {
    config: IConfigReducer;
    dispatchConfig: Dispatch<configActionTypes>;
}

export const ConfigContext = createContext<ConfigContextProps>({
    ...configReducerInitialState,
    config: configReducerInitialState,
    dispatchConfig: () => {}
});

export const useConfig = () => useContext(ConfigContext);
