import { ReactElement, memo } from 'react';
import Stack from '@mui/material/Stack';
import { Add } from '@mui/icons-material';
import { Action, AdminNavbar } from '../components';

function AdminDashboardPage(): ReactElement {
    return (
        <AdminNavbar
            sx={{
                minHeight: '100vh',
                display: 'grid',
                alignItems: 'center'
            }}
            disableFooter
        >
            <Stack mt={6} spacing={6}>
                <Action
                    av='Button'
                    label='Add Blog'
                    startIcon={<Add />}
                    size='large'
                />
                <Action
                    av='Button'
                    label='Add Contribution'
                    startIcon={<Add />}
                    to={{ path: '/admin/contributors' }}
                    size='large'
                />
                <Action
                    av='Button'
                    label='Add Sponsor'
                    startIcon={<Add />}
                    size='large'
                />
            </Stack>
            {/* 
            <Alert variant='dialog' {...alert}>
                <AddBlog />
            </Alert>
            <Alert variant='dialog' {...alert}>
                <AddBlog />
            </Alert> */}
        </AdminNavbar>
    );
}

export default memo(AdminDashboardPage);
