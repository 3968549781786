import { ReactElement, ChangeEvent, memo, ReactNode } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import MuiTextField, { StandardTextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { BaseFieldProps, IFieldOption } from './Field';

export type WithTextFieldProps<T = {}> = BaseFieldProps &
    T & {
        onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
        startAdornment?: ReactNode;
        endAdornment?: ReactNode;
        multiline?: boolean;
        rows?: number;
        fieldVariant?: 'outlined' | 'standard' | 'filled' | undefined;
        options?: Array<IFieldOption>;
        TextFieldProps?: StandardTextFieldProps;
        type?: TextFieldTypes;
    };

function TextField({
    onChange,
    name,
    label,
    value,
    size,
    options,
    startAdornment,
    endAdornment,
    TextFieldProps,
    multiline,
    rows,
    type,
    fieldVariant,
    optional
}: WithTextFieldProps): ReactElement {
    return (
        <MuiTextField
            fullWidth
            InputProps={{
                startAdornment: startAdornment && (
                    <InputAdornment position='start'>
                        {startAdornment}
                    </InputAdornment>
                ),
                endAdornment: endAdornment ? (
                    <InputAdornment position='end'>
                        {endAdornment}
                    </InputAdornment>
                ) : (
                    optional && (
                        <InputAdornment position='end'>
                            <Typography variant='caption' component='legend'>
                                *optional
                            </Typography>
                        </InputAdornment>
                    )
                )
            }}
            name={name}
            value={value}
            label={`${label ?? name}:`}
            variant={fieldVariant}
            required={!optional}
            select={options !== undefined}
            onChange={onChange}
            multiline={multiline}
            rows={rows}
            size={size}
            type={type ?? 'text'}
            {...TextFieldProps}
        >
            {options?.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label ?? option.value}
                </MenuItem>
            ))}
        </MuiTextField>
    );
}

export default memo(TextField) as typeof TextField;
