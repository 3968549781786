import { ReactElement } from "react";
import Pages from "../pages";

export const adminRoutes = [
  "/admin",
  "/admin/blogs",
  "/admin/contributors",
] as const;

export const publicRoutes = [
  "/",
  "/blogs",
  "/donate",
  "/login",
  "/why-we-walk",
  "/previous-walk",
  "/previous-walk-2023",
] as const;

export type routeTypes =
  | typeof adminRoutes[number]
  | typeof publicRoutes[number];

export interface INavigate {
  path: routeTypes;
  ext?: string | undefined;
}

export type WithLink<T = {}> = T & {
  to?: INavigate;
  href?: string | undefined;
  blank?: boolean;
  target?: "_self" | "_blank" | "_parent" | "_top" | (string & {});
};

interface IRoutes {
  path: routeTypes;
  element: ReactElement;
}

const routes: {
  public: IRoutes[];
  admin: IRoutes[];
} = {
  public: [
    {
      path: "/",
      element: <Pages.Home />,
    },
    {
      path: "/blogs",
      element: <Pages.Blog />,
    },
    {
      path: "/why-we-walk",
      element: <Pages.WhyWeWalk />,
    },
    {
      path: "/previous-walk",
      element: <Pages.PreviousWalk />,
    },
    {
      path: "/previous-walk-2023",
      element: <Pages.PreviousWalk2023 />,
    },
    {
      path: "/donate",
      element: <Pages.Donate />,
    },
    {
      path: "/login",
      element: <Pages.Login />,
    },
  ],
  admin: [
    {
      path: "/admin",
      element: <Pages.AdminContributors />,
    },
    {
      path: "/admin/contributors",
      element: <Pages.AdminContributors />,
    },
    {
      path: "/admin/blogs",
      element: <Pages.AdminBlogs />,
    },
  ],
};

export default routes;
