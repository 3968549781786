import { ReactElement, memo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Map from "../../assets/gifs/map-2024.png";
import Slogan from "../../assets/images/logos/cape2care_slogan.png";
import useDimensions from "../../hooks/useDimensions";
import Action from "../custom/action";
import { Palette } from "../../config";

function HomeHeader(): ReactElement {
  const { width } = useDimensions();
  return (
    <Stack
      width="100%"
      direction={{ xs: "column", md: "row" }}
      alignItems="center"
      spacing={2}
      my={4}
    >
      <Stack width="fit-content" alignItems="center">
        <Box
          component="img"
          src={Slogan}
          alt={Slogan}
          maxWidth="100%"
          sx={{ objectFit: "cover", objectPosition: "center" }}
        />
        <Typography variant="body1" my={4} lineHeight={1.8}>
          A journey of a thousand miles begins with one single step. If we can
          change one life with one single step, imagine how many lives we can
          change with 1545km worth of steps.
        </Typography>
        <Stack direction="row" spacing={5}>
          <Action
            av="Button"
            variant="text"
            to={{ path: "/donate" }}
            LordIcon={{
              icon: "Donate",
              secondary: Palette.secondary?.main,
              delay: 2000,
              style: { maxWidth: "9rem", cursor: "pointer" },
            }}
          />
          <Action
            av="Button"
            variant="text"
            href="#contact"
            LordIcon={{
              icon: "GetInvolved",
              tooltip: "Get Involved",
              secondary: Palette.secondary?.main,
              delay: 2000,
              style: { maxWidth: "9rem", cursor: "pointer" },
            }}
          />
        </Stack>
      </Stack>
      <Box
        component="img"
        src={Map}
        width={width > 900 ? "50%" : "100%"}
        draggable={false}
        flex={1}
        sx={{ objectFit: "cover", objectPosition: "center" }}
      />
    </Stack>
  );
}

export default memo(HomeHeader);