import { console_log } from '../../config';

export const configReducerInitialState = {
    tableSettings: {
        dense: false,
        disableBadge: false,
        disablePagination: false,
        disableSearch: false
    },
    donateOptionState: 0
};

export type IConfigReducer = typeof configReducerInitialState;

export const configReducerInit = (initialState: IConfigReducer) => ({
    tableSettings: {
        dense:
            localStorage.getItem('Table_Settings_Density') === 'true'
                ? !initialState.tableSettings.dense
                : initialState.tableSettings.dense,
        disableBadge:
            localStorage.getItem('Table_Settings_Badge') === 'true'
                ? !initialState.tableSettings.disableBadge
                : initialState.tableSettings.disableBadge,
        disablePagination:
            localStorage.getItem('Table_Settings_Pagination') === 'true'
                ? !initialState.tableSettings.disablePagination
                : initialState.tableSettings.disablePagination,
        disableSearch:
            localStorage.getItem('Table_Settings_Search') === 'true'
                ? !initialState.tableSettings.disableSearch
                : initialState.tableSettings.disableSearch
    },
    donateOptionState: initialState.donateOptionState
});

export type configActionTypes =
    | { type: 'RESET' }
    | { type: 'TOGGLE_TABLE_SETTINGS_DENSITY' }
    | { type: 'TOGGLE_TABLE_SETTINGS_BADGE' }
    | { type: 'TOGGLE_TABLE_SETTINGS_PAGINATION' }
    | { type: 'TOGGLE_TABLE_SETTINGS_SEARCH' }
    | { type: 'SET_DONATE_OPTION'; option: number };

export const configReducer = (
    state: IConfigReducer,
    action: configActionTypes
) => {
    console_log(action.type, '\n', action);
    console_log('====================================');
    switch (action.type) {
        case 'RESET':
            return configReducerInit(configReducerInitialState);
        case 'TOGGLE_TABLE_SETTINGS_DENSITY':
            localStorage.setItem(
                'Table_Settings_Density',
                JSON.stringify(!state.tableSettings.dense)
            );
            return {
                ...state,
                tableSettings: {
                    ...state.tableSettings,
                    dense: !state.tableSettings.dense
                }
            };
        case 'TOGGLE_TABLE_SETTINGS_BADGE':
            localStorage.setItem(
                'Table_Settings_Badge',
                JSON.stringify(!state.tableSettings.disableBadge)
            );
            return {
                ...state,
                tableSettings: {
                    ...state.tableSettings,
                    disableBadge: !state.tableSettings.disableBadge
                }
            };
        case 'TOGGLE_TABLE_SETTINGS_PAGINATION':
            localStorage.setItem(
                'Table_Settings_Pagination',
                JSON.stringify(!state.tableSettings.disablePagination)
            );
            return {
                ...state,
                tableSettings: {
                    ...state.tableSettings,
                    disablePagination: !state.tableSettings.disablePagination
                }
            };
        case 'TOGGLE_TABLE_SETTINGS_SEARCH':
            localStorage.setItem(
                'Table_Settings_Search',
                JSON.stringify(!state.tableSettings.disableSearch)
            );
            return {
                ...state,
                tableSettings: {
                    ...state.tableSettings,
                    disableSearch: !state.tableSettings.disableSearch
                }
            };
        case 'SET_DONATE_OPTION':
            return { ...state, donateOptionState: action.option };
        default:
            return state;
    }
};
