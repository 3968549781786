import { FunctionComponent, ReactElement, memo } from "react";
import Paper from "@mui/material/Paper";
import MuiTable, { TableProps as MuiTableProps } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { useConfig } from "../../../context";
import Loading from "../Loading";
import Toolbar from "./Toolbar";
import TableHead from "./Table.Head";
import TableData from "./Table.Data";
import { ITableAction } from "./Table.Buttons";
import useTable from "./useTable";

export interface TableBaseProps<GenericData> {
  columns: Array<keyof GenericData>;
  editableColumns?: Array<keyof GenericData>;
  sortColumns?: Array<keyof GenericData>;
  addHandler?: (row: GenericData) => void;
  editHandler?: (row: GenericData) => void;
  actionsPosition?: "start" | "end";
  actions?: Array<ITableAction<GenericData>>;
}

export interface TableProps<GenericData> extends TableBaseProps<GenericData> {
  data: GenericData[];
  sortByDefault: keyof GenericData;
  loading?: boolean;
  disableSort?: boolean;
  disableBadge?: boolean;
  disablePagination?: boolean;
  search?: string | null;
  title?: string;
  headColor?: string;
  bodyColor?: string;
  initialRow?: GenericData;
  TableProps?: MuiTableProps;
  addDrawer?: FunctionComponent<{
    open: boolean;
    toggle: (condition?: boolean) => void;
  }>;
}

function Table<TableGenericData>(
  props: TableProps<TableGenericData>
): ReactElement {
  const { actionsPosition = "start" } = props;
  const { tableSettings } = useConfig();
  const tableLogic = useTable(props);

  return (
    <>
      {props.addDrawer && (
        <props.addDrawer
          open={tableLogic.openAdd}
          toggle={tableLogic.toggleAdd}
        />
      )}
      <TableContainer
        sx={{ mt: 6, bgcolor: props.headColor ?? "#eee" }}
        component={Paper}
      >
        <Toolbar
          title={props.title}
          data={props.data}
          add={tableLogic.openAdd}
          toggleAdd={tableLogic.toggleAdd}
          disableBadge={props.disableBadge}
          addHandler={props.addHandler}
        />
        <MuiTable
          aria-label={`${props.title} optimised table`}
          size={tableSettings.dense ? "small" : "medium"}
          {...props.TableProps}
        >
          <TableHead
            sortBy={tableLogic.sortBy}
            sortDirection={tableLogic.sortDirection}
            onUpdateSort={tableLogic.onUpdateSort}
            {...props}
            actionsPosition={actionsPosition}
          />
          <Loading loading={props.loading}>
            <TableBody sx={{ bgcolor: props.bodyColor ?? "#e9e9e9" }}>
              {props.initialRow && tableLogic.openAdd && !props.addDrawer && (
                <TableData
                  {...props}
                  add
                  row={props.initialRow}
                  openAdd={tableLogic.openAdd}
                  toggleAdd={tableLogic.toggleAdd}
                  addHandler={props.addHandler}
                  actionsPosition={actionsPosition}
                />
              )}
              {props.data
                .filter(tableLogic.filterData)
                .sort(tableLogic.getComparator)
                .slice(
                  tableSettings.disablePagination || props.disablePagination
                    ? 0
                    : tableLogic.page * tableLogic.rowsPerPage,
                  tableSettings.disablePagination || props.disablePagination
                    ? props.data.length
                    : tableLogic.page * tableLogic.rowsPerPage +
                        tableLogic.rowsPerPage
                )
                .map((row, index) => (
                  <TableData
                    key={index}
                    row={row}
                    {...props}
                    actionsPosition={actionsPosition}
                  />
                ))}
              {!tableSettings.disablePagination && tableLogic.emptyRows > 0 && (
                <TableRow
                  style={{
                    height:
                      (tableSettings.dense ? 33 : 53) * tableLogic.emptyRows,
                  }}
                >
                  <TableCell
                    colSpan={
                      (props.actions !== undefined ? props.actions.length : 0) +
                      (props.editHandler || props.addHandler ? 1 : 0) +
                      props.columns.length
                    }
                  />
                </TableRow>
              )}
            </TableBody>
          </Loading>
        </MuiTable>
        {(!tableSettings.disablePagination && !props.loading) ||
          (props.disablePagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={props.data?.filter(tableLogic.filterData).length}
              rowsPerPage={tableLogic.rowsPerPage}
              page={tableLogic.page}
              onPageChange={tableLogic.changePageHandler}
              labelRowsPerPage=""
              onRowsPerPageChange={tableLogic.changeRowsPerPageHandler}
            />
          ))}
      </TableContainer>
    </>
  );
}

export default memo(Table) as typeof Table;
