import { ReactElement, ReactNode, useCallback, memo } from "react";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import Action, { WithIconButtonProps } from "../action";
import Close from "@mui/icons-material/Close";

export interface TableButtonsProps<ButtonsGenericRow>
  extends Omit<TableButtonProps<ButtonsGenericRow>, "action">,
    EditButtonProps<ButtonsGenericRow> {
  add?: boolean;
  actions?: ITableAction<ButtonsGenericRow>[];
}

function TableButtons<TableButtonsGeneric>(
  props: TableButtonsProps<TableButtonsGeneric>
): ReactElement {
  return (
    <>
      {props.clickHandler && <EditButton {...props} />}
      {props.actions?.map((action) =>
        props.add ? (
          <TableCell padding="checkbox" />
        ) : (
          <TableButton {...props} action={action} />
        )
      )}
    </>
  );
}

export interface ITableAction<GenericRow> {
  onClick: (row: GenericRow) => void;
  icon?: ReactNode;
  color?: ColorOptions;
  props?: WithIconButtonProps;
}

interface TableButtonProps<GenericRow> {
  row: GenericRow;
  action: ITableAction<GenericRow>;
  head?: boolean;
}

function TableButton<ButtonGenericRow>({
  row,
  action,
  head,
}: TableButtonProps<ButtonGenericRow>): ReactElement {
  const clickHandler = useCallback(() => action.onClick(row), [action, row]);
  return (
    <TableCell
      padding="checkbox"
      component={head ? "th" : "td"}
      scope={head ? "row" : undefined}
    >
      <Action
        av="IconButton"
        Icon={action.icon}
        color={action.color}
        onClick={clickHandler}
        {...action.props}
      />
    </TableCell>
  );
}

interface EditButtonProps<GenericRow> {
  row: GenericRow;
  submitData: GenericRow;
  open?: boolean;
  head?: boolean;
  clickHandler?: (row: GenericRow) => void;
  toggle?: (condition?: any) => void;
}

function EditButton<ButtonGenericRow>({
  row,
  submitData,
  open,
  head,
  clickHandler,
  toggle,
}: EditButtonProps<ButtonGenericRow>): ReactElement {
  const save = useCallback(() => {
    toggle && toggle(false);
    clickHandler && clickHandler(submitData);
  }, [clickHandler, submitData, toggle]);

  const cancel = useCallback(() => toggle && toggle(false), [toggle]);

  return (
    <TableCell
      padding="checkbox"
      component={head ? "th" : "td"}
      scope={head ? "row" : undefined}
    >
      {open && (
        <Action
          av="IconButton"
          Icon={<Close />}
          color="error"
          onClick={cancel}
        />
      )}
      <Action
        av="IconButton"
        Icon={open ? <CheckIcon /> : <EditIcon />}
        disabled={open && row === submitData}
        onClick={open ? save : toggle}
        size={open ? "medium" : "medium"}
      />
    </TableCell>
  );
}

export default memo(TableButtons) as typeof TableButtons;