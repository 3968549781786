// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Name: "DWFC – Cape 2 Care",
  BankDetails: {
    BusinesesAccount: "Cape 2 Care",
    AccountNumber: "629 187 88334",
    BranchCode: 230145,
    BranchName: "Zambesi Drive",
    Reference: "Name and Contact Number",
  },
  ContactDetails: {
    Email: "cape2care@dwfc.org.za",
    PhoneMichelle: "072 564 7495",
    PhoneMarleen: "082 574 2693",
  },
  RegistrationDetails: {
    NPO: "182-738",
    NPC: "2008 /027293/ 08",
    Article: "Registered Article 18 A",
  },
};