import { ReactElement } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Routes from "./Routes";
import { Providers } from "./context";
import Palette from "./config/Palette";

export default function App(): ReactElement {
  const muiTheme = createTheme({
    palette: Palette,
    typography: { fontFamily: "Rubik" },
  });
  return (
    <ThemeProvider theme={muiTheme}>
      <Providers>
        <Routes />
      </Providers>
    </ThemeProvider>
  );
}