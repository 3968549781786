import { useCallback, useReducer } from 'react';
import { useFetch, useNavigate } from '../../hooks';
import { AuthContext } from './Auth.Context';
import {
    authReducer,
    authReducerInit,
    authReducerInitialState
} from './auth.reducer';

export default function AuthProvider({ children }: WithChildren) {
    const [auth, dispatchAuth] = useReducer(
        authReducer,
        authReducerInitialState,
        authReducerInit
    );
    const [post] = useFetch('/users/logout');
    const { navigate } = useNavigate();
    const logout = useCallback(async () => {
        await post(
            {
                data: {
                    MemberGuid: auth.MemberGuid,
                    SessionGuid: auth.SessionGuid
                },
                message: 'Logged out!'
            },
            () => {
                dispatchAuth({ type: 'LOGOUT' });
                navigate('/login');
            }
        );
    }, [auth.MemberGuid, auth.SessionGuid, navigate, post]);
    const setSession = useCallback(
        Login => dispatchAuth({ type: 'LOGIN', Login }),
        []
    );
    const updateSession = useCallback(
        Role => dispatchAuth({ type: 'UPDATE_ROLE', Role }),
        []
    );
    return (
        <AuthContext.Provider
            value={{
                ...auth,
                auth,
                session: {
                    MemberGuid: auth.MemberGuid,
                    SessionGuid: auth.SessionGuid
                },
                dispatchAuth,
                setSession,
                updateSession,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
