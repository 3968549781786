import { ReactElement, useCallback, memo } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Action, DonateMoney, DonateOther } from "../components";
import Paper from "@mui/material/Paper";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Donation from "../assets/images/donation.png";
import DonationItem from "../assets/images/donate_item.png";
import useConfig from "../context/useConfig";
import PublicNavbar from "../components/navbar/Public.Navbar";

function DonatePage(): ReactElement {
  const { donateOptionState, dispatchConfig } = useConfig();
  const changeDisplay = useCallback(
    (num: 0 | 1 | 2) => () =>
      dispatchConfig({ type: "SET_DONATE_OPTION", option: num }),
    [dispatchConfig]
  );
  return (
    <PublicNavbar
      sx={{
        py: 4,
        minHeight: "100vh",
        display: "grid",
        placeContent: "center",
      }}
    >
      <Paper
        sx={{
          py: 2,
          px: donateOptionState === 0 ? 3 : 2,
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          width="100%"
        >
          {donateOptionState !== 0 && (
            <Action
              av="IconButton"
              Icon={<ArrowBackIosIcon />}
              onClick={changeDisplay(0)}
            />
          )}
          <Typography variant="subtitle1" component="legend" ml="auto">
            {donateOptionState === 0
              ? "Select a donation option"
              : donateOptionState === 1
              ? "Make a donation"
              : "What would you like to donate?"}
          </Typography>
        </Stack>
        {donateOptionState === 0 ? (
          <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
            <Action
              av="Image"
              src={Donation}
              maxWidth="13rem"
              onClick={changeDisplay(1)}
            />
            <Action
              av="Image"
              src={DonationItem}
              maxWidth="13rem"
              onClick={changeDisplay(2)}
            />
          </Stack>
        ) : donateOptionState === 1 ? (
          <DonateMoney />
        ) : (
          <DonateOther />
        )}
        <Typography
        variant="h5"
        component="h5"
        fontWeight={600}
        textAlign="center"
        paddingTop="1em"
      >
        EFT Payment Details:
      </Typography>
        <Typography
        // variant="h6"
        // component="h6"
        fontWeight={400}
        textAlign="center"
        paddingTop="1em"
      >
        FNB: Cape 2 Care <br/>
        Account Number: 629 187 88334 <br/>
        Branch Code: 230145 <br/>
        Branch Name: Zambezi Drive <br/>
        Reference: Name and Contact Number<br/>
        <br/>
        We are a registered Article 18A orginization!
      </Typography>
      </Paper>
    </PublicNavbar>
  );
}

export default memo(DonatePage);