import { ReactElement, RefObject, memo } from "react";
import { payfastConfig, routeConfig } from "../../config";

export interface IPayfast {
  price: number;
  name: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  path: string;
  method?: boolean;
}

interface PayfastProps extends IPayfast {
  payfastRef: RefObject<HTMLInputElement>;
}

function Payfast({
  price,
  name,
  first_name,
  last_name,
  email,
  path,
  payfastRef,
  method,
}: PayfastProps): ReactElement {
  return (
    <form action={payfastConfig.PayfastLink} method="post">
      <input
        type="hidden"
        name="merchant_id"
        value={payfastConfig.MerchantID}
      />
      <input
        type="hidden"
        name="merchant_key"
        value={payfastConfig.MerchantKey}
      />
      <input type="hidden" name="name_first" value={first_name} />
      <input type="hidden" name="name_last" value={last_name} />
      <input type="hidden" name="email_address" value={email} />
      <input type="hidden" name="amount" value={price} />
      <input type="hidden" name="item_name" value={name} />
      <input
        type="hidden"
        name="return_url"
        value={routeConfig.baseHomeUrl + path}
      />
      <input type="hidden" name="cancel_url" value={routeConfig.baseHomeUrl} />
      <input type="hidden" name="notify_url" value={routeConfig.baseHomeUrl} />
      <input type="submit" ref={payfastRef} style={{ display: "none" }} />
    </form>
  );
}

export default memo(Payfast);