// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    date_posted: new Date(),
    description:
      "We arrived at the Paarl 12pm this afternoon, and we are fecking dying!!🥵",
    id: "123abc",
    image_data: "[/images/dummy.jpg]",
    title: "Die Paarl",
  },
  {
    date_posted: new Date(),
    description:
      "Today we walked 26km from Grasmere to Vredefort. It was a very hot day today and I think we lost 5kg just in sweat😅. We arrived safely in Vredefort at around 19:30 and had the most delicious dinner sponsored by “Spar”. Thank you for everyone who is supporting us and donating to our cause. Off to bed we go, getting ready for Day 2",
    id: "456def",
    image_data: "[/images/dummy.jpg]",
    title: "Grasmere Tolplaza ~ Vredefort",
  },
  {
    date_posted: new Date(),
    description:
      "We arrived at the Paarl 12pm this afternoon, and we are fecking dying!!🥵",
    id: "789ghi",
    image_data: "[/images/dummy.jpg]",
    title: "Die Paarl",
  },
];